import { Button } from "@/components/ui/button";
import { useGetCommunityCategories } from "@/data/queries/useGetCommunityCategories";
import { useSearchParams } from "react-router-dom";

export const CommunityCategories = () => {
  const { data: categories } = useGetCommunityCategories();
  const [searchParams, setSearchParams] = useSearchParams({});

  return (
    <div className="flex gap-2 overflow-x-auto pb-1.5 lg:mx-auto lg:flex-wrap lg:pb-0">
      {categories?.map(category => (
        <Button
          variant="tertiary"
          key={category._id}
          className={`rounded-lg border-neutral-100 bg-neutral-100 hover:border-neutral-200 ${
            searchParams.get("category") === category._id &&
            "border border-primary-500 bg-primary-200 text-primary-500 focus:bg-primary-200 lg:hover:bg-primary-100"
          }`}
          onClick={() => {
            setSearchParams(
              prev => {
                if (prev.get("category") === category._id) {
                  prev.delete("category");
                  return prev;
                }
                prev.set("category", category._id);
                return prev;
              },
              { replace: true }
            );
          }}
        >
          {category.title}
        </Button>
      ))}
    </div>
  );
};
