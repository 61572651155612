import { StatsHeader } from "./components/StatsHeader";
import StatsTopAgents from "./components/StatsTopAgents/StatsTopAgents";
import { StatsBasic } from "./components/StatsBasic";
import { StatsMainChartSection } from "./components/StatsMainChartSection";
import StatsAgentsOverview from "./components/StatsAgentsOverview/StatsAgentsOverview";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import { AgentChatHistoryDialog } from "./components/AgentChatHistoryDialog/AgentChatHistoryDialog";
import { useGetUser } from "@/data/queries/useGetUser";
import { SubscriptionTiers } from "@/types/subscriptions";

export const Stats = () => {
  const {
    currentRange,
    startDate,
    endDate,
    chatHistoryDialogProps,
    setChatHistoryDialogProps,
    isChatHistoryDialogOpen,
  } = useStatsContext();
  const { user } = useGetUser();

  const isUpgradeNeeded =
    user?.subscription.tier === SubscriptionTiers.FREE || user?.subscription.tier === SubscriptionTiers.STARTUP;

  const StatsContent = (
    <>
      <StatsHeader />

      <div className="overflow-y-scroll px-5 pb-10 lg:px-6">
        <StatsBasic isUpgradeNeeded={isUpgradeNeeded} />

        <div className="mt-2.5 grid grid-cols-1 gap-4 sm:mt-7 min-[1881px]:grid-cols-2 min-[1881px]:gap-7">
          <StatsTopAgents isUpgradeNeeded={isUpgradeNeeded} />
          <div className="lg:row-span-2 xl:h-full">
            <StatsMainChartSection isUpgradeNeeded={isUpgradeNeeded} />
          </div>
          <StatsAgentsOverview isUpgradeNeeded={isUpgradeNeeded} />
        </div>
      </div>
    </>
  );

  return (
    <div className="flex w-full flex-col rounded-t-[20px] bg-stats-view pt-4 lg:pt-4">
      {StatsContent}
      <AgentChatHistoryDialog
        key={`${currentRange}-${startDate?.toString()}-${endDate?.toString()}-${chatHistoryDialogProps?.channel}`}
        isOpen={isChatHistoryDialogOpen}
        onClose={() => setChatHistoryDialogProps(null)}
        agentId={chatHistoryDialogProps?.agent._id ?? ""}
        agentName={chatHistoryDialogProps?.agent?.name ?? ""}
        initialChannel={chatHistoryDialogProps?.channel}
        initialCurrentRange={currentRange}
        initialStartDate={startDate}
        initialEndDate={endDate}
      />
    </div>
  );
};
