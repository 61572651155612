import { Icons } from "@/components/ui/icons";
import { domElementIds } from "@/types/dom-element-ids";
import { AgentFormTabs, agentTabs } from "../types/AgentFormTypes";
import { useAgentFormNavigationTab } from "../hooks/useAgentFormNavigationTab";
import { IconButton } from "@/components/ui/icon-button";

export const AgentTabsMobile = ({
  onNextPrevButtonClick,
  disabled,
  isAgentCreation,
}: {
  onNextPrevButtonClick: (type: "next" | "prev") => void;
  disabled: boolean;
  isAgentCreation: boolean;
}) => {
  const { activeTab, currentTabIndex, isFirstTab, isLastTab } = useAgentFormNavigationTab();
  const isNextBtnDisabled = isLastTab || disabled || (isAgentCreation && activeTab === AgentFormTabs.KNOWLEDGE);

  return (
    <>
      <div
        id={domElementIds.AGENT_FORM_MOBILE_SCROLL_TO}
        className="relative flex items-center justify-between border-b-2 border-primary-500 bg-primary-100 py-2 font-semibold text-primary-500"
      >
        <IconButton
          variant="tertiary"
          size="medium"
          onClick={() => onNextPrevButtonClick("prev")}
          disabled={isFirstTab || disabled}
          icon={<Icons.ChevronRight className="rotate-180" />}
        />

        <span className="grid place-items-center text-sm text-primary-black absolute-center">
          {activeTab.name}
          <span className="text-[11px] leading-none text-primary-500">
            ({currentTabIndex + 1}/{agentTabs.length})
          </span>
        </span>

        <IconButton
          variant="tertiary"
          size="medium"
          onClick={() => onNextPrevButtonClick("next")}
          disabled={isNextBtnDisabled}
          icon={<Icons.ChevronRight />}
        />
      </div>
      <div className="mb-5 flex justify-between text-xs font-medium text-primary-500">
        <span>{!isFirstTab && agentTabs[currentTabIndex - 1].name}</span>
        <span>{!isLastTab && agentTabs[currentTabIndex + 1].name}</span>
      </div>
    </>
  );
};
