import * as React from "react";
import { Slot, Slottable } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { LoadingSpinner } from "./loading-spinner";

const defaultVariants = {
  variant: "primary",
  variantColor: "purple",
  size: "small",
  roundedType: "square",
  contentType: "default",
} as const;

const iconButtonVariants = cva(
  "relative group inline-flex items-center justify-center whitespace-nowrap font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-1 disabled:pointer-events-none dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300",
  {
    variants: {
      variant: {
        primary: "",
        secondary: "",
        tertiary: "",
      },
      variantColor: {
        purple: "",
        grey: "",
      },
      size: {
        tiny: "p-[6px]",
        small: "p-[6px]",
        medium: "p-2",
        custom: "",
      },
      roundedType: {
        rounded: "rounded-full",
        square: "rounded-md",
      },
      contentType: {
        icon: "",
        default: "",
      },
    },
    compoundVariants: [
      {
        variant: "primary",
        variantColor: "purple",
        class:
          "bg-primary-400 text-white hover:bg-primary-600 disabled:bg-primary-200 active:bg-primary-500 focus-visible:ring-primary-400",
      },
      {
        variant: "primary",
        variantColor: "grey",
        class:
          "bg-neutral-50 text-neutral-750 border border-neutral-400 hover:bg-neutral-200 hover:border-neutral-400 disabled:bg-white disabled:border-white disabled:text-neutral-400 active:bg-neutral-300 active:border-neutral-100 focus-visible:ring-neutral-400",
      },
      {
        variant: "secondary",
        variantColor: "purple",
        class:
          "bg-primary-50 text-primary-400 border border-primary-400 hover:bg-primary-100 hover:border-primary-500 hover:text-primary-600 disabled:bg-primary-50 disabled:border-primary-50 disabled:text-primary-200 active:bg-primary-200 active:border-primary-500 active:text-primary-400 focus-visible:ring-primary-400",
      },
      {
        variant: "secondary",
        variantColor: "grey",
        class:
          "bg-neutral-50 text-neutral-750 border-transparent hover:bg-neutral-200 disabled:bg-white disabled:text-neutral-400 active:bg-neutral-300 focus-visible:ring-neutral-400",
      },
      {
        variant: "tertiary",
        variantColor: "purple",
        class:
          "bg-primary-50 text-primary-400 border-primary-50 hover:bg-primary-100 hover:border-primary-100 hover:text-primary-600 disabled:border-primary-50 disabled:text-primary-200 active:bg-primary-200 active:border-primary-200 active:text-primary-400 focus-visible:ring-primary-400",
      },
      {
        variant: "tertiary",
        variantColor: "grey",
        class:
          "bg-transparent text-neutral-750 border border-transparent hover:bg-neutral-100 hover:border-transparent disabled:bg-transparent disabled:border-transparent disabled:text-neutral-400 active:bg-neutral-300 active:border-neutral-300 active:text-neutral-750 focus-visible:ring-neutral-400",
      },
    ],
    defaultVariants,
  }
);

export interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof iconButtonVariants> {
  asChild?: boolean;
  loading?: boolean;
  icon?: React.ReactElement<{ className?: string }>;
  prefixIcon?: React.ReactElement<{ className?: string }>;
  suffixIcon?: React.ReactElement<{ className?: string }>;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      className,
      variant,
      variantColor = variant === "tertiary" ? "grey" : "purple",
      size,
      roundedType,
      loading = false,
      asChild = false,
      icon,
      prefixIcon,
      suffixIcon,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";

    const buttonProps = {
      type: props.type ?? "button",
      disabled: loading || props.disabled,
    };

    const iconSizes = {
      tiny: "w-4 h-4",
      small: "w-6 h-6",
      medium: "w-6 h-6",
      custom: "",
    };

    const iconColorClasses = {
      primary: {
        purple: "text-white",
        grey: "text-neutral-750 group-disabled:text-neutral-400",
      },
      secondary: {
        purple: "text-primary-400 group-disabled:text-primary-200",
        grey: "text-neutral-750 group-disabled:text-neutral-400",
      },
      tertiary: {
        purple: "text-primary-400 group-disabled:text-primary-200",
        grey: "text-neutral-750 group-disabled:text-neutral-400",
      },
    };

    const iconSize = iconSizes[size ?? defaultVariants.size];
    const iconColorClass = iconColorClasses[variant ?? "primary"][variantColor ?? "purple"];

    return (
      <Comp
        className={cn(
          iconButtonVariants({
            variant,
            variantColor,
            size,
            roundedType,
            contentType: icon ? "icon" : "default",
            className,
          }),
          {
            "text-transparent": loading,
          }
        )}
        ref={ref}
        {...(!asChild && { ...buttonProps })}
        {...props}
      >
        <Slottable>
          {icon ? (
            <>
              {React.cloneElement(icon, { className: cn(iconSize, iconColorClass, icon.props.className) })}
              {props.children}
            </>
          ) : (
            <>
              {prefixIcon &&
                React.cloneElement(prefixIcon, { className: cn("mr-2 w-4 h-4", prefixIcon.props.className) })}
              {props.children}
              {suffixIcon &&
                React.cloneElement(suffixIcon, { className: cn("ml-2 w-4 h-4", suffixIcon.props.className) })}
            </>
          )}
        </Slottable>
        {loading && <LoadingSpinner className="absolute-center" />}
      </Comp>
    );
  }
);
IconButton.displayName = "Button";

export { IconButton, iconButtonVariants };
