import { Icons } from "@/components/ui/icons";
import { useGetUser } from "@/data/queries/useGetUser";
import { Button } from "../ui/button";

export const AssociatedAccount = () => {
  const { user } = useGetUser();

  return (
    <>
      <div className="py-6 text-lg font-bold">Associated Account</div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-4">
            <Icons.Google className="size-12" />
            <div>
              <div className="text-base font-bold">Google Account</div>
              <span className="font-medium text-neutral-500">{user?.provider === "google" && user?.email}</span>
            </div>
          </div>
        </div>
        {user?.provider === "facebook" && (
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-4">
              <Icons.Facebook className="size-12 text-primary-black" />
              <div>
                <div className="text-base font-bold">Facebook Account</div>
                <span className="font-medium text-neutral-500">{user?.name}</span>
              </div>
            </div>
            <div>
              <Button variant="tertiary" className="my-1 w-24 rounded-full border-blue-600 text-blue-600">
                {user?.provider === "facebook" ? "Enable" : "Disabled"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
