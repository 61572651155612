import StatsEngagementOverview from "./StatsEngagementOverview/StatsEngagementOverview";
import { useEffect, useRef, useState } from "react";
import type { TypeFromConstObject } from "@/types/types";
import { StatsAllAgents } from "./StatsAllAgents/StatsAllAgents";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { StatsIntervalPicker } from "./StatsIntervalPicker";
import type { StatsInterval } from "@/types/stats";
import { StatsIntervals } from "@/types/stats";
import { useGetEngagementOverviewStats } from "@/data/queries/stats/useGetEngagementOverviewStats";
import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import { useGetAllAgentsStats } from "@/data/queries/stats/useGetAllAgentsStats";
import { StatsMainChartSkeleton } from "./loaders/EngagementOverviewSkeleton";
import { useGetSingleAgentEngagementStats } from "@/data/queries/stats/useGetSingleAgentEngagementStats";
import { StatsSingleAgentEngagement } from "./StatsSingleAgentEngagement/StatsSingleAgentEngagement";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import { statsMockAllAgentsData, statsMockEngagementData } from "../constants/statsMockData";
import { StatsBlur } from "./common/StatsBlur";
import { StatsUpgradeMessage } from "./common/StatsUpgradeMessage";

const SectionTabs = {
  ENGAGEMENT_OVERVIEW: "engagement_overview",
  ALL_AGENTS: "all_agents",
  SINGLE_AGENT: "single_agent",
} as const;

type SectionTab = TypeFromConstObject<typeof SectionTabs>;

export const StatsMainChartSection = ({ isUpgradeNeeded }: { isUpgradeNeeded: boolean }) => {
  const { currentRange, startDate, endDate, selectedAgentId, setSelectedAgentId, activeChannelsOrigins } =
    useStatsContext();

  const [activeSectionTab, setActiveSectionTab] = useState<SectionTab>(
    selectedAgentId ? SectionTabs.SINGLE_AGENT : SectionTabs.ENGAGEMENT_OVERVIEW
  );

  const [statsInterval, setStatsInterval] = useState<StatsInterval>(StatsIntervals.DAILY);

  const [headerTotalOffset, setHeaderTotalOffset] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeaderTotalOffset((headerRef.current?.offsetHeight ?? 0) + (headerRef.current?.offsetTop ?? 0));
  }, []);

  const { data: engagementData, isFetching: isFetchingEngagement } = useGetEngagementOverviewStats({
    currentRange,
    startDate,
    endDate,
    channels: activeChannelsOrigins,
    interval: statsInterval,
    enabled: activeSectionTab === SectionTabs.ENGAGEMENT_OVERVIEW && !isUpgradeNeeded,
  });
  const engagementDataToShow = isUpgradeNeeded ? statsMockEngagementData : engagementData;

  const { data: allAgentsData, isFetching: isFetchingAllAgents } = useGetAllAgentsStats({
    currentRange,
    startDate,
    endDate,
    channels: activeChannelsOrigins,
    enabled: activeSectionTab === SectionTabs.ALL_AGENTS && !isUpgradeNeeded,
  });
  const allAgentsDataToShow = isUpgradeNeeded ? statsMockAllAgentsData : allAgentsData;

  const { data: singleAgentEngagementData, isFetching: isFetchingSingleAgentEngagement } =
    useGetSingleAgentEngagementStats({
      currentRange,
      startDate,
      endDate,
      channels: activeChannelsOrigins,
      agentId: selectedAgentId!,
      interval: statsInterval,
      enabled: !!selectedAgentId && activeSectionTab === SectionTabs.SINGLE_AGENT && !isUpgradeNeeded,
    });

  const titleMap = {
    [SectionTabs.ENGAGEMENT_OVERVIEW]: "Engagement Overview",
    [SectionTabs.ALL_AGENTS]: "All Agents",
    [SectionTabs.SINGLE_AGENT]: singleAgentEngagementData?.agent?.name,
  };

  const isLoading =
    (activeSectionTab === SectionTabs.ENGAGEMENT_OVERVIEW && isFetchingEngagement) ||
    (activeSectionTab === SectionTabs.ALL_AGENTS && isFetchingAllAgents) ||
    (activeSectionTab === SectionTabs.SINGLE_AGENT && isFetchingSingleAgentEngagement);

  useEffect(() => {
    if (selectedAgentId) {
      setActiveSectionTab(SectionTabs.SINGLE_AGENT);
    } else {
      setActiveSectionTab(SectionTabs.ENGAGEMENT_OVERVIEW);
    }
  }, [selectedAgentId]);

  return (
    <div className="relative flex h-full min-h-[550px] flex-col items-start gap-2.5 rounded-md border border-border-light bg-white px-5 py-6 shadow-md">
      {isLoading ? (
        <StatsMainChartSkeleton />
      ) : (
        <>
          {isUpgradeNeeded && (
            <StatsBlur
              className="rounded-md"
              style={{
                top: headerTotalOffset,
                height: `calc(100% - ${headerTotalOffset}px)`,
              }}
            >
              <div className="flex h-full flex-col items-center justify-center gap-4">
                <StatsUpgradeMessage suffixText="to see how your agents perform" />
              </div>
            </StatsBlur>
          )}
          <div ref={headerRef} className="flex w-full items-center justify-between">
            <DropdownMenu modal={false}>
              <DropdownMenuTrigger className="flex items-center gap-2">
                <h2 className="text-left text-2xl font-bold">{titleMap[activeSectionTab]}</h2>
                <Icons.ChevronRight className="w-8 rotate-90 text-neutral-400" />
              </DropdownMenuTrigger>
              <DropdownMenuContent onClick={e => e.stopPropagation()} align="end">
                <DropdownMenuItem
                  className="flex items-center gap-1.5 text-xs"
                  onClick={() => {
                    setSelectedAgentId(null);
                    setActiveSectionTab(SectionTabs.ALL_AGENTS);
                  }}
                >
                  <Icons.Check
                    className={cn("opacity-0", {
                      "opacity-100": activeSectionTab === SectionTabs.ALL_AGENTS,
                    })}
                  />
                  {titleMap[SectionTabs.ALL_AGENTS]}
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="flex items-center gap-1.5 text-xs"
                  onClick={() => {
                    setSelectedAgentId(null);
                    setActiveSectionTab(SectionTabs.ENGAGEMENT_OVERVIEW);
                  }}
                >
                  <Icons.Check
                    className={cn("opacity-0", {
                      "opacity-100": activeSectionTab === SectionTabs.ENGAGEMENT_OVERVIEW,
                    })}
                  />
                  {titleMap[SectionTabs.ENGAGEMENT_OVERVIEW]}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>

            {activeSectionTab === SectionTabs.ENGAGEMENT_OVERVIEW && (
              <StatsIntervalPicker
                statsInterval={statsInterval}
                onStatsIntervalChange={setStatsInterval}
                disabled={isUpgradeNeeded}
              />
            )}
          </div>

          {activeSectionTab === SectionTabs.ENGAGEMENT_OVERVIEW && (
            <StatsEngagementOverview data={engagementDataToShow} />
          )}
          {activeSectionTab === SectionTabs.ALL_AGENTS && <StatsAllAgents data={allAgentsDataToShow} />}
          {activeSectionTab === SectionTabs.SINGLE_AGENT && (
            <StatsSingleAgentEngagement data={singleAgentEngagementData} />
          )}
        </>
      )}
    </div>
  );
};
