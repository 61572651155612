import * as React from "react";

import { cn } from "@/lib/utils";

export interface DivProps extends React.HTMLAttributes<HTMLDivElement> {}

const LoginContainer = React.forwardRef<HTMLDivElement, DivProps>(({ className, ...props }, ref) => {
  return (
    <div
      className={cn(
        "flex w-[95%] flex-col rounded-[20px] border border-neutral-200 bg-white p-5 pb-10 shadow-register absolute-center sm:w-[559px] sm:rounded-[20px] sm:p-10 sm:px-[94px]",
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
LoginContainer.displayName = "LoginContainer";

export { LoginContainer };
