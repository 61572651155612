import { DeleteTeamMemberDialog } from "@/components/dialogs/TeamDialogs/DeleteTeamMemberDialog";
import { cn } from "@/lib/utils";
import { UserStatus, type User } from "@/types/user";
import { Avatar } from "@/components/Avatar";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icons } from "@/components/ui/icons";
import { useToggleWorkspaceUserRole } from "@/data/mutations/workspace/useToggleWorkspaceUserRole";
import { useGetUser } from "@/data/queries/useGetUser";
import { IconButton } from "@/components/ui/icon-button";

export const TeamMembers = ({ members }: { members: User[] }) => {
  const { user } = useGetUser();
  const { data: workspaceDetails } = useGetWorkspaceDetails();
  const { mutate: toggleUserRole } = useToggleWorkspaceUserRole();

  const isWorkspaceAdmin = workspaceDetails?.isAdmin;

  return (
    <div className="flex flex-col gap-4">
      {members.map(member => {
        const memberInvitationPending = member.status === UserStatus.INVITE_PENDING;
        const isAdminMember = workspaceDetails?.customer?.administrators?.includes(member._id);
        const memberRole = isAdminMember ? "Admin" : memberInvitationPending ? "Pending" : "Member";
        return (
          <div key={member._id} className="flex w-full gap-4">
            <Avatar src={member?.avatar} name={member?.name} className="size-8 rounded-full lg:size-10" size="custom" />
            <div className="flex w-full items-center justify-between gap-5">
              <div>
                <div className="text-sm font-medium">
                  {member.name} {user?._id === member._id && <span className="text-neutral-400">(You)</span>}
                  {memberInvitationPending && <span className="text-neutral-500">{"<Invitiation sent>"}</span>}
                </div>
                <span className="text-xs text-neutral-400">{member.email}</span>
              </div>
              <div className="grid grid-cols-[60px_40px] items-center gap-4">
                <span
                  className={cn(
                    "text-right text-xs",
                    memberInvitationPending ? "text-neutral-400" : "text-primary-400"
                  )}
                >
                  {memberRole}
                </span>
                {isWorkspaceAdmin && user?._id !== member._id && (
                  <DropdownMenu modal={false}>
                    <DropdownMenuTrigger onClick={e => e.stopPropagation()} asChild>
                      <IconButton
                        variant="tertiary"
                        roundedType="rounded"
                        size="tiny"
                        className="aspect-square"
                        icon={<Icons.Dots2 className="rotate-90 text-black" />}
                      />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="start">
                      <DropdownMenuItem
                        disabled={memberInvitationPending}
                        className="flex items-center gap-2 border-b-[1px] text-xs"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          toggleUserRole({ userId: member._id });
                        }}
                      >
                        <Icons.Edit2 />
                        {isAdminMember ? "Change to Member" : "Set to Admin"}
                      </DropdownMenuItem>

                      <DropdownMenuItem
                        className="flex items-center gap-1.5 text-xs focus:text-error"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <DeleteTeamMemberDialog userId={member._id} />
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
