import { useResetPassword } from "@/data/mutations/useResetPassword";
import { useForm } from "react-hook-form";
import { emailRegex } from "@/constants/regexps";
import { FormInput } from "@/components/ui/FormInput";
import { Icons } from "@/components/ui/icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { Button } from "@/components/ui/button";

export const ForgotPassword = () => {
  const { mutate, isSuccess, error, isPending } = useResetPassword();
  const navigate = useNavigate();
  const form = useForm({ defaultValues: { email: "" } });
  const { handleSubmit, reset } = form;

  const submitResetPassword = (formData: { email: string }) => {
    const { email } = formData;
    mutate({ email }, { onSuccess: () => reset() });
  };

  if (isSuccess) {
    return (
      <div className="grid place-items-center gap-5">
        <p className="flex items-center text-2xl font-bold lg:text-[28px]">
          <Icons.Mails className="mr-4" />
          Email Sent
        </p>
        <p className="text-center text-base font-semibold">
          A verification email has been sent to you. Check spam folder if you do not see it.
        </p>
        <Button variant="primary" loading={isPending} className="w-full" onClick={() => navigate(ROUTES.login)}>
          Back to Login
        </Button>
      </div>
    );
  }

  return (
    <>
      <h2 className="mb-8 text-center text-2xl font-bold leading-9 tracking-tight lg:text-[28px]">
        Enter your email address
      </h2>

      <form className="space-y-6" onSubmit={handleSubmit(submitResetPassword)}>
        <FormInput
          formObj={form}
          name="email"
          autoComplete="email"
          inputClassName="h-[50px]"
          required
          placeholder="Email address"
          rules={{
            required: "Email is required",
            pattern: {
              value: emailRegex,
              message: "Entered value does not match email format",
            },
          }}
        />

        {error && (
          <div className="py-2 text-center text-xs font-medium leading-5 text-red-600">
            <div>{error.response?.data.message}</div>
          </div>
        )}

        <Button type="submit" loading={isPending} className="w-full">
          Continue
        </Button>
      </form>
      <Button variant="ghost" loading={isPending} className="mt-2 w-full" onClick={() => navigate(ROUTES.login)}>
        Go back
      </Button>
    </>
  );
};
