import { Icons } from "@/components/ui/icons";
import type { UseAudioRecorder } from "../hooks/useAudioRecorder";
import type { IconButtonProps } from "@/components/ui/icon-button";
import { IconButton } from "@/components/ui/icon-button";

type VoiceMessageButtonProps = {
  audioRecorder: UseAudioRecorder;
  submitSendMessage: (messageText: string, audio?: { blob: Blob; duration: number }) => Promise<void>;
} & IconButtonProps;

export const VoiceMessageButton = ({ audioRecorder, submitSendMessage, ...props }: VoiceMessageButtonProps) => {
  const { startRecording, isRecording, stopRecording } = audioRecorder;

  const handleStopRecording = async () => {
    const audioBlob = await stopRecording();
    if (audioBlob) {
      void submitSendMessage("", audioBlob);
    }
  };
  return isRecording ? (
    <IconButton
      type="button"
      size="medium"
      variant="primary"
      icon={<Icons.ArrowUp />}
      onClick={() => !props.disabled && handleStopRecording()}
      {...props}
    >
      <span className="sr-only">Send your voice message</span>
    </IconButton>
  ) : (
    <IconButton
      type="button"
      size="medium"
      variant="primary"
      icon={<Icons.Microphone />}
      onClick={() => !props.disabled && startRecording()}
      {...props}
    >
      <span className="sr-only">Record a voice message</span>
    </IconButton>
  );
};
