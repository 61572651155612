import { useForm } from "react-hook-form";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { FormInput } from "@/components/ui/FormInput";
import { useGetUser } from "@/data/queries/useGetUser";
import { useChangePassword } from "@/data/mutations/useChangePassword";
import { toast } from "react-toastify";
import { useState } from "react";
import { Button } from "../ui/button";

export const ChangePasswordDialog = () => {
  const { user } = useGetUser();
  const { mutate } = useChangePassword();
  const form = useForm({ defaultValues: { previousPassword: "", newPassword: "", repeatNewPassword: "" } });
  const { handleSubmit, reset } = form;
  const [open, setOpen] = useState(false);
  const [isMatchPassword, setIsMatchPassword] = useState(true);

  const submitChangePassword = (formData: {
    previousPassword: string;
    newPassword: string;
    repeatNewPassword: string;
  }) => {
    const { previousPassword, newPassword, repeatNewPassword } = formData;
    if (newPassword !== repeatNewPassword) {
      return setIsMatchPassword(false);
    }
    if (user) {
      mutate(
        { email: user?.email, password: previousPassword, newPassword },
        {
          onSuccess: () => {
            toast.success("Password changed!");
            reset();
            setOpen(false);
          },
        }
      );
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="primary" className="my-1">
          Change Password
        </Button>
      </DialogTrigger>
      <DialogContent className="w-full pb-5 sm:max-h-[600px] sm:max-w-[400px]" variant="custom">
        <DialogHeader>
          <DialogTitle className="mb-6">Change Password</DialogTitle>
        </DialogHeader>
        <div className="px-5">
          <p className="mt-6 pb-8 text-sm text-neutral-500">
            Password must contain at least 1 letter, 1 number, and 1 symbol. Minimum lenghth is 12 characters
          </p>
          <form id="changePasswordForm" className="flex flex-col gap-y-6" onSubmit={handleSubmit(submitChangePassword)}>
            <FormInput
              label="Enter your current password"
              formObj={form}
              showRevealPasswordButton
              name="previousPassword"
              autoComplete="current-password"
              placeholder="Current password"
              required
              rules={{
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters",
                },
              }}
            />
            <FormInput
              label="Enter your new password"
              formObj={form}
              showRevealPasswordButton
              name="newPassword"
              autoComplete="new-password"
              placeholder="New password"
              required
              rules={{
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters",
                },
              }}
            />
            <FormInput
              label="Confirm your new password"
              formObj={form}
              showRevealPasswordButton
              name="repeatNewPassword"
              autoComplete="repeat-new-password"
              placeholder="Confirm password"
              required
              rules={{
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters",
                },
              }}
              aria-invalid={!isMatchPassword}
              aria-errormessage="input-error-repeatNewPassword"
            />
            {!isMatchPassword && (
              <div
                id="input-error-repeatNewPassword"
                className="py-4 text-center text-xs font-medium leading-5 text-red-600"
              >
                <div>Password does not match!</div>
              </div>
            )}
          </form>
        </div>
        <DialogFooter className="static">
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button form="changePasswordForm" type="submit">
            Change password
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
