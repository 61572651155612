import { createContext } from "react";
import type { DateRangeType } from "@/types/datepicker";
import type { StatsPageChannelTab } from "./StatsProvider";
import type { Agent } from "@/types/agent";
import type { Origin } from "@/types/origin";

export type StatsProps = {
  currentRange: DateRangeType;
  setCurrentRange: React.Dispatch<React.SetStateAction<DateRangeType>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedAgentId: string | null;
  setSelectedAgentId: (agentId: string | null) => void;
  chatHistoryDialogProps: {
    agent: Agent;
    channel?: Origin;
  } | null;
  setChatHistoryDialogProps: React.Dispatch<
    React.SetStateAction<{
      agent: Agent;
      channel?: Origin;
    } | null>
  >;
  isChatHistoryDialogOpen: boolean;
  activeChannelsOrigins: number[];
  activeChannelTab: StatsPageChannelTab;
  switchChannelTab: ({ newTab }: { newTab: StatsPageChannelTab }) => void;
};

export const StatsContext = createContext<StatsProps | null>(null);
