import { useCopyNotify } from "@/hooks/useCopyNotify";
import { toast } from "react-toastify";
import { Icons } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { useGenerateNewApiKey } from "@/data/mutations/useGenerateNewApiKey";
import { useGetUser } from "@/data/queries/useGetUser";
import { useState } from "react";
import type { User } from "@/types/user";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "../ui/button";
import { IconButton } from "../ui/icon-button";

export const GenerateNewAPIKey = () => {
  const [, copy] = useCopyNotify();
  const { user } = useGetUser();
  const { mutate, isPending } = useGenerateNewApiKey();
  const [apiKey, setApiKey] = useState<User["apiKey"]>(() => user?.apiKey || "");
  const [isGenerateKeyDialogOpen, setIsGenerateKeyDialogOpen] = useState(false);

  const generateNewApiKey = () => {
    mutate(undefined, {
      onSuccess: newApiKey => {
        setApiKey(newApiKey);
        toast.success("New API key generated!");
        setIsGenerateKeyDialogOpen(false);
      },
    });
  };

  return (
    <div className="border-b-[1.5px] pb-2">
      <div className="text-lg font-bold">API Setting</div>
      <div className="flex flex-col items-start justify-between gap-2 border-neutral-200 pt-6 lg:flex-row lg:items-center">
        <div className="text-sm">Do not share your API key or expose it publicly.</div>
        <Dialog open={isGenerateKeyDialogOpen} onOpenChange={setIsGenerateKeyDialogOpen}>
          <DialogTrigger asChild>
            <Button variant="tertiary" prefixIcon={<Icons.UploadCloud aria-hidden />}>
              Generate A New API Key
            </Button>
          </DialogTrigger>

          <DialogContent variant="small">
            <DialogHeader>
              <DialogTitle>Generate API Key</DialogTitle>
            </DialogHeader>
            <DialogDescription className="font-medium">
              Are you sure you want to generate a new API key?
              <br /> This action can affect your currently embeded agents.
            </DialogDescription>
            <DialogFooter>
              <DialogClose asChild>
                <Button variant="secondary">Cancel</Button>
              </DialogClose>
              <Button onClick={generateNewApiKey} loading={isPending}>
                Generate
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Input label="API Key" value={apiKey} inputClassName="bg-primary-50 disabled:cursor-default" disabled>
          <IconButton
            variant="tertiary"
            size="tiny"
            icon={<Icons.Copy />}
            className="absolute right-3 top-1/2 -translate-y-1/2"
            onClick={() => copy(apiKey)}
          />
        </Input>
      </div>
    </div>
  );
};
