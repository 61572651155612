import { DialogHeader } from "@/components/ui/dialog";
import { Icons } from "@/components/ui/icons";
import { StripePaymentDetails } from "./components/StripePaymentDetails";
import { StripePaymentForm } from "./components/StripePaymentForm";
import type { CreateSubscriptionPlanBody } from "@/data/mutations/subscriptions/useCreateSubscriptionPlan";
import { useCreateSubscriptionPlan } from "@/data/mutations/subscriptions/useCreateSubscriptionPlan";
import { useEffect, useState } from "react";
import { useGetTotalUsingCoupon } from "@/data/queries/subscriptions/useGetTotalUsingCoupon";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import { IconButton } from "@/components/ui/icon-button";
import { StripePaymentWrapper } from "@/components/dialogs/SubscriptionTierDialog/components/StripePaymentSummary/components/StripePaymentWrapper";
import type { BillingPlanPricing, SubscriptionPeriod } from "@/types/subscriptions";
import { StripePlanDetails } from "@/components/dialogs/SubscriptionTierDialog/components/StripePaymentSummary/components/StripePlanDetails";
import { StripePaymentCategoryWrapper } from "@/components/dialogs/SubscriptionTierDialog/components/StripePaymentSummary/components/StripePaymentCategoryWrapper";
import { useGetBillingPlans } from "@/data/queries/subscriptions/useGetBillingPlans";
import { StripePaymentWorkspace } from "@/components/dialogs/SubscriptionTierDialog/components/StripePaymentSummary/components/StripePaymentWorkspace";
import { useGetCustomerBillingInfo } from "@/data/queries/subscriptions/useGetCustomerBillingInfo";
import { BillingPaymentMethod } from "@/components/Profiles/Billing/BillingPaymentMethod";
import { useUpdateSubscriptionPlan } from "@/data/mutations/subscriptions/useUpdateSubscriptionPlan";
import { useCreateUserLicences } from "@/data/mutations/workspace/useCreateUserLicences";
import { SubscriptionTiers } from "@/types/subscriptions";
import { CancelSubscriptionDialog } from "@/components/dialogs/BillingDialogs/CancelSubscriptionDialog";
import { useIsMutating } from "@tanstack/react-query";
import { createPaymentMethodMutationKeys } from "@/data/mutations/subscriptions/useCreatePaymentMethod";

export type OnSubmitStripePlan = ({
  paymentMethodId,
  customerDetails,
  seats,
}: {
  paymentMethodId: CreateSubscriptionPlanBody["paymentMethodId"];
  customerDetails: CreateSubscriptionPlanBody["customerDetails"];
  seats?: CreateSubscriptionPlanBody["seats"];
}) => void;

export const StripePaymentSummary = () => {
  const { data: plans } = useGetBillingPlans();
  const { mutate: createUserLicence, isPending: isLicenceCreating } = useCreateUserLicences();
  const { data: billingInfo } = useGetCustomerBillingInfo();
  const {
    setActiveStep,
    selectedPlan,
    selectedSeats,
    setSelectedSeats,
    defaultSeats,
    selectedSeatsPlan,
    setIsPendingPayment,
    isPendingPayment,
  } = useSubscriptionTierDialogContext();
  const { mutate: createSubscriptionPlan, isPending: isPlanCreating } = useCreateSubscriptionPlan();
  const [coupon, setCoupon] = useState<string | undefined>("");
  const { mutate: updateSubscriptionPlan, isPending: isPlanUpdating } = useUpdateSubscriptionPlan();
  const isPaymentMethodCreating = !!useIsMutating({ mutationKey: createPaymentMethodMutationKeys.all });

  useEffect(() => {
    setIsPendingPayment(isLicenceCreating || isPlanCreating || isPlanUpdating || isPaymentMethodCreating);
  }, [isLicenceCreating, isPlanCreating, isPlanUpdating, isPaymentMethodCreating, setIsPendingPayment]);

  const selectedPlanId = selectedPlan?.plan.price[selectedPlan.period].planId;
  const couponQuery = useGetTotalUsingCoupon({
    planId: selectedPlanId ?? "",
    coupon: coupon ?? "",
  });
  const paymentMethod = billingInfo?.paymentMethods?.[0];

  if (!selectedPlan) {
    return null;
  }

  if (selectedPlan.plan.tier === SubscriptionTiers.FREE) {
    return <CancelSubscriptionDialog openDialog />;
  }

  const onSubmit: OnSubmitStripePlan = ({ paymentMethodId, customerDetails }) => {
    const { planId } = selectedPlan.plan.price[selectedPlan.period];

    if (paymentMethod) {
      updateSubscriptionPlan(
        { targetPlanId: planId },
        {
          onSuccess: () => {
            if (selectedSeats === defaultSeats || !selectedSeatsPlan) {
              return setActiveStep("stripePaymentSuccess");
            }

            createUserLicence(
              {
                planId: selectedSeatsPlan,
                quantity: selectedSeats - defaultSeats,
                convertToCustomer: true,
              },

              {
                onSuccess: () => {
                  setActiveStep("stripePaymentSuccess");
                },
              }
            );
          },
        }
      );
    } else {
      createSubscriptionPlan(
        {
          planId,
          paymentMethodId,
          customerDetails,
          coupon: couponQuery.data?.coupon,
          seats: selectedSeats - defaultSeats,
        },

        {
          onSuccess: () => setActiveStep("stripePaymentSuccess"),
        }
      );
    }
  };

  return (
    <>
      <DialogHeader className="min-h-12">
        <IconButton
          variant="tertiary"
          size="small"
          icon={<Icons.Arrow />}
          onClick={() => (isPendingPayment ? undefined : setActiveStep("subscriptionTiers"))}
        >
          <span className="sr-only">go back to plans</span>
        </IconButton>
      </DialogHeader>
      <div className="overflow-auto px-8 py-4">
        <div className="grid items-start gap-6 lg:grid-cols-[572px_auto]">
          <div className="flex flex-col gap-4">
            <StripePaymentCategoryWrapper title="Selected Plan">
              <StripePaymentWrapper>
                <div className="text-xl font-bold text-primary-400">{selectedPlan.plan.name}</div>
                <div className="text-xs font-semibold text-neutral-500">{selectedPlan.plan.features_as_text}</div>
              </StripePaymentWrapper>
              {(Object.keys(selectedPlan.plan.price) as SubscriptionPeriod[]).map(period => {
                const planPricingInfo: BillingPlanPricing = selectedPlan.plan.price[period];
                return (
                  <StripePlanDetails
                    key={period}
                    period={period}
                    selectedPlan={selectedPlan}
                    planPricingInfo={planPricingInfo}
                  />
                );
              })}
            </StripePaymentCategoryWrapper>
            <StripePaymentWorkspace
              plans={plans}
              selectedPlan={selectedPlan}
              selectedSeats={selectedSeats}
              setSelectedSeats={setSelectedSeats}
              defaultSeats={defaultSeats}
            />
            {paymentMethod ? (
              <BillingPaymentMethod billingInfo={billingInfo} />
            ) : (
              <StripePaymentCategoryWrapper
                title={
                  <>
                    Payment Information <Icons.Lock className="h-4 w-4 text-primary-400" />
                  </>
                }
              >
                <StripePaymentWrapper>
                  <StripePaymentForm onSubmit={onSubmit} />
                </StripePaymentWrapper>
              </StripePaymentCategoryWrapper>
            )}
          </div>
          <StripePaymentDetails
            isLoading={isPendingPayment}
            couponQuery={couponQuery}
            setCoupon={setCoupon}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </>
  );
};
