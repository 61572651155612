import { Dialog, DialogClose, DialogContent, DialogFooter, DialogTrigger } from "@/components/ui/dialog";
import { Icons } from "@/components/ui/icons";
import type { DomainLinks, EmbedKnowledges } from "@/types/agent";
import { Input } from "@/components/ui/input";
import { AgentDetailsEmbedKnowledgeList } from "./AgentDetailsEmbedKnowledgeList";
import { useState } from "react";
import { sortWebByDomainsToSeparateArrays } from "@/utils/getDomains";
import { IconButton } from "@/components/ui/icon-button";
import { Button } from "@/components/ui/button";

type AgentDetailsEmbedKnowledgeDialogProps = {
  embedKnowledges: {
    web: EmbedKnowledges[];
    doc: EmbedKnowledges[];
    faq: EmbedKnowledges[];
  };
  handleSimplePinChange: (checked: boolean, idOrIds: string | string[]) => void;
  sortedWebData: DomainLinks[];
  knowledgesCount: number;
  handleClearPins: () => void;
  selectedChatKnowledge: string[];
};

export const AgentDetailsEmbedKnowledgeDialog = ({
  embedKnowledges,
  sortedWebData,
  handleSimplePinChange,
  knowledgesCount,
  handleClearPins,
  selectedChatKnowledge,
}: AgentDetailsEmbedKnowledgeDialogProps) => {
  // implement useDefferedValue on the query in case of performance issues
  const [searchQuery, setSearchQuery] = useState<string>("");

  const filteredEmbedKnowledges = {
    web: embedKnowledges.web.filter(knowledge => knowledge.source.toLowerCase().includes(searchQuery.toLowerCase())),
    doc: embedKnowledges.doc.filter(
      knowledge =>
        knowledge.filename.toLowerCase().includes(searchQuery.toLowerCase()) ||
        knowledge.description.toLowerCase().includes(searchQuery.toLowerCase())
    ),
    faq: embedKnowledges.faq.filter(
      knowledge =>
        knowledge.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
        knowledge.description.toLowerCase().includes(searchQuery.toLowerCase())
    ),
  };
  const filteredEmbedKnowledgesCount =
    filteredEmbedKnowledges.web.length + filteredEmbedKnowledges.doc.length + filteredEmbedKnowledges.faq.length;

  const dialogEmbedKnowledges = searchQuery ? filteredEmbedKnowledges : embedKnowledges;
  const dialogSortedWebData = searchQuery
    ? sortWebByDomainsToSeparateArrays(filteredEmbedKnowledges.web)
    : sortedWebData;
  return (
    <Dialog>
      <DialogTrigger asChild>
        <IconButton variant="tertiary" size="tiny" icon={<Icons.Maximize />} />
      </DialogTrigger>
      <DialogContent hideCloseButton variant="medium" className="h-[min(800px,100dvh)]">
        <div className="flex size-full flex-col px-6 pt-5">
          <div className="flex items-center justify-between border-b border-neutral-200 py-2">
            <Button
              disabled={!selectedChatKnowledge.length}
              variant="tertiary"
              size="tiny"
              className="pl-0 text-base"
              onClick={handleClearPins}
            >
              Clear
            </Button>
            {selectedChatKnowledge.length > 0 && (
              <span className="text-sm font-medium text-primary-400">
                {selectedChatKnowledge.length === 0
                  ? "all knowledge selected"
                  : `selected knowledge (${selectedChatKnowledge.length}/${knowledgesCount})`}
              </span>
            )}
            <DialogClose asChild>
              <IconButton variant="tertiary" size="medium" icon={<Icons.Minimize />} />
            </DialogClose>
          </div>
          <div className="flex size-full flex-col text-sm font-medium">
            <div className="relative my-4">
              <Input
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                placeholder="Search knowledge"
                inputClassName="pl-9 sm:pl-9 placeholder:text-slate-400 text-sm lg:text-sm"
              />
              <Icons.Search className="absolute left-3 top-1/2 -translate-y-1/2 text-primary-500" />
              {!!searchQuery && (
                <IconButton
                  variant="tertiary"
                  size="tiny"
                  icon={<Icons.Close />}
                  className="absolute right-3 top-1/2 -translate-y-1/2"
                  onClick={() => setSearchQuery("")}
                />
              )}
            </div>
            <div className="size-full max-h-[min(600px,100dvh-200px)] overflow-y-auto">
              <AgentDetailsEmbedKnowledgeList
                selectedChatKnowledge={selectedChatKnowledge}
                embedKnowledges={dialogEmbedKnowledges}
                sortedWebData={dialogSortedWebData}
                handleSimplePinChange={handleSimplePinChange}
              />
            </div>
            {!filteredEmbedKnowledgesCount && <p>No results for this query</p>}
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="tertiary">Done</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
