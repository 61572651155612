import { cn } from "@/lib/utils";

type Props = {
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

export const StatsBlur = ({ className, children, style }: Props) => {
  return (
    <div className={cn("absolute left-0 top-0 z-20 h-full w-full backdrop-blur-[10px]", className)} style={style}>
      {children}
    </div>
  );
};
