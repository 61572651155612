import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogDescription,
} from "@/components/ui/dialog";
import { useDeleteTool } from "@/data/mutations/useDeleteTool";
import type { Tool } from "@/types/tools";
import DeleteBin from "@/assets/images/DeleteBin.svg";
import type { F1 } from "@/types/types";
import { Button } from "@/components/ui/button";

type DeleteAgentDialogProps = {
  toolId: Tool["_id"];
  isDialogOpen: boolean;
  setShowDialog: F1<boolean>;
};

export const DeleteToolDialog = ({ toolId, isDialogOpen, setShowDialog }: DeleteAgentDialogProps) => {
  const { mutate: deleteToolMutation, isPending } = useDeleteTool();

  const deleteTool = () => {
    deleteToolMutation(toolId, { onSuccess: () => setShowDialog(false) });
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setShowDialog}>
      <DialogContent variant="medium" className="h-[350px]">
        <DialogHeader></DialogHeader>
        <DialogDescription className="flex flex-col items-center justify-center gap-3">
          <img src={DeleteBin} alt="Delete Bin" className="w-20" />
          <div className="text-center text-xl font-bold text-zinc-800"> Are you sure you want to Delete the tool?</div>
          <div className="text-center text-sm text-neutral-400">
            Remove the tool from the toolbox to delete it from all agents.
          </div>
        </DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button onClick={deleteTool} loading={isPending}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
