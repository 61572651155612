import { Icons } from "@/components/ui/icons";
import { Switch } from "@/components/ui/switch";
import { useState } from "react";
import { WebsiteEmbeddingDialog } from "./WebsiteEmbeddingDialog";
import { cn } from "@/lib/utils";
import { useAgentFormContext } from "../../../../../hooks/useAgentFormContext";
import { Controller } from "react-hook-form";
import { Button } from "@/components/ui/button";

export const WebsiteEmbeddingTile = () => {
  const { control } = useAgentFormContext();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Controller
        control={control}
        name="publish"
        render={({ field: { value, onChange } }) => (
          <>
            <div
              className={cn(
                "relative flex max-w-[30.25rem] flex-col justify-between gap-4 overflow-hidden rounded-md p-4",
                value.isPublished ? "border border-neutral-300" : "shadow-integration"
              )}
            >
              <div className="flex gap-4">
                <Icons.Globe2 />
                <div className="flex w-full flex-col gap-4">
                  <div className="flex items-center justify-between gap-4">
                    <div className="flex gap-4">
                      <span className="text-base font-bold">Website Embedding</span>
                      <span className="flex items-center gap-1 text-xs font-medium">
                        {value.isPublished ? (
                          <>
                            <span className="inline-block size-2.5 rounded-full bg-success"></span>
                            Online
                          </>
                        ) : (
                          <>
                            <span className="inline-block size-2.5 rounded-full bg-error"></span>
                            Offline
                          </>
                        )}
                      </span>
                    </div>
                    <Switch
                      checked={value.isPublished}
                      onCheckedChange={checked => {
                        if (!value.isConfigured) {
                          setIsDialogOpen(true);
                          return;
                        }

                        onChange({ ...value, isPublished: checked });
                      }}
                    />
                  </div>
                  <p className="text-xs font-medium leading-relaxed">Deploy the agent to a website as a widget.</p>
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-between gap-y-1">
                {value.isConfigured ? (
                  <>
                    <span className="flex items-center gap-1 px-4 text-xs font-medium text-neutral-400">
                      <Icons.CheckCircle className="text-success" /> Configured
                    </span>
                    <Button
                      variant="tertiary"
                      size="tiny"
                      className="text-primary-400"
                      onClick={() => setIsDialogOpen(true)}
                      suffixIcon={<Icons.Settings2 />}
                    >
                      Manage
                    </Button>
                  </>
                ) : (
                  <Button variant="tertiary" roundedType="rounded" size="tiny" onClick={() => setIsDialogOpen(true)}>
                    Configure
                  </Button>
                )}
              </div>
            </div>
            <WebsiteEmbeddingDialog isDialogOpen={isDialogOpen} setIsDialogOpen={() => setIsDialogOpen(false)} />
          </>
        )}
      />
    </>
  );
};
