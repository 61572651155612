import type { PropsWithChildren, ReactElement } from "react";

type StripePaymentCategoryWrapperProps = PropsWithChildren & {
  title: string | ReactElement;
};

export const StripePaymentCategoryWrapper = ({ children, title }: StripePaymentCategoryWrapperProps) => {
  return (
    <div className="flex flex-col gap-4">
      <span className="flex items-center gap-1 font-bold">{title}</span>
      {children}
    </div>
  );
};
