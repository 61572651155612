import type { Usage } from "@/types/subscriptions";
import type { ReactElement } from "react";
import { Card, CardContent, CardDescription, CardHeader } from "@/components/ui/card";
import { AccountActivitiesProgressBar } from "@/components/Profiles/AccountActivitiesProgressBar";

type AccountActivitiesCardType = {
  usage?: Usage;
  header: string;
  children: ReactElement;
};

export const AccountActivitiesCard = ({ usage, header, children }: AccountActivitiesCardType) => {
  const descriptionBuilder = () => {
    const description = usage?.limit && Math.abs(usage?.limit);
    if (header === "Agents") {
      return null;
    }

    const descriptionTitle = header === "Web Resources" || header === "Documents" ? "used" : "published";

    return `of ${description} ${descriptionTitle}`;
  };

  if (!usage) {
    return;
  }

  return (
    <Card className="flex h-44 w-32 flex-col items-center rounded-none border-none shadow-xl">
      <CardHeader className="flex-grow p-2 text-sm">{header}</CardHeader>
      <AccountActivitiesProgressBar usage={usage}>{children}</AccountActivitiesProgressBar>
      <CardContent className="pb-0">
        <h3>{usage.currentUsage}</h3>
      </CardContent>
      <CardDescription className={`pb-2 ${header === "Agents" && "mb-5"} text-xs font-medium text-neutral-400`}>
        {descriptionBuilder()}
      </CardDescription>
    </Card>
  );
};
