import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { userKeys } from "@/data/queries/useGetUser";
import { workspaceDetailsKeys } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { customerBillingInfoKeys } from "@/data/queries/subscriptions/useGetCustomerBillingInfo";

type Payload = {
  planId: string;
  quantity: number;
  convertToCustomer: boolean;
};

type Response = {
  success: boolean;
};
const createUserLicence = async (payload: Payload) => {
  const { data } = await restClient.post<Response>(apiPaths.createUserLicence, payload);
  return data;
};

export const useCreateUserLicences = () => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError<{ message: string }>, Payload>({
    mutationFn: createUserLicence,
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: userKeys.all }),
        queryClient.invalidateQueries({ queryKey: workspaceDetailsKeys.all }),
        queryClient.invalidateQueries({ queryKey: customerBillingInfoKeys.all }),
      ]);
    },
  });
};
