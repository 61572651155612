import { Icons } from "@/components/ui/icons";
import Community from "@/assets/images/Community.svg";
import { useState } from "react";
import { PublishAgentDialog } from "@/components/dialogs/PublishAgentDialog/PublishAgentDialog";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { UnpublishAgentDialog } from "@/components/dialogs/UnpublishAgentDialog/UnpublishAgentDialog";
import { useAgentFormContext } from "../../../hooks/useAgentFormContext";
import { Button } from "@/components/ui/button";

export const CommunityPublishing = () => {
  const form = useAgentFormContext();

  const [isPostAgentDialogOpen, setIsPostAgentDialogOpen] = useState(false);
  const [agentId, publishedToCommunity] = form.getValues(["_id", "publishedToCommunity"]);

  return (
    <div className="border-b border-neutral-100 pb-6">
      <div className="flex flex-wrap items-center justify-between gap-3">
        <div className="flex gap-1">
          <h4>Agent Space Publishing</h4>
          <HybridTooltipPopover heading="Publish your Agent to the community">
            <p>Share you Agent with other AI enthusiasts in our community!</p>
          </HybridTooltipPopover>
        </div>
        {publishedToCommunity ? (
          <UnpublishAgentDialog agentId={agentId} renderButton={() => <Button variant="tertiary">Unpublish</Button>} />
        ) : (
          <Button onClick={() => setIsPostAgentDialogOpen(true)} prefixIcon={<Icons.Upload />}>
            Publish
          </Button>
        )}
      </div>

      <div className="mt-5 flex flex-col items-center justify-evenly gap-4 rounded-md border border-neutral-300 bg-violet-50 p-6 sm:flex-row">
        <img src={Community} className="w-[234px]" />
        <div className="space-y-3">
          <h4>Publish Your Agent on Our Community</h4>
          <p className="text-sm font-medium text-neutral-700">
            Share your Agent to inspire the community and gain subscribers. Publish now and watch your impact grow!
          </p>
        </div>
      </div>

      {isPostAgentDialogOpen && (
        <PublishAgentDialog
          isDialogOpen={isPostAgentDialogOpen}
          setShowDialog={setIsPostAgentDialogOpen}
          alreadySelectedAgentId={agentId}
        />
      )}
    </div>
  );
};
