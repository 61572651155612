import { Card, CardContent, CardDescription, CardFooter, CardHeader } from "@/components/ui/card";
import { useGetUser } from "@/data/queries/useGetUser";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { AccountActivitiesCard } from "@/components/Profiles/AccountActivitiesCard";
import { Icons } from "@/components/ui/icons";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { AccountActivitiesUsersStats } from "./AccountActivitiesUsersStats/AccountActivitiesUsersStats";
import { SubscriptionTiers } from "@/types/subscriptions";
import { Button } from "../ui/button";

export const AccountActivities = () => {
  const { user, isLoading } = useGetUser();
  const { setNavigationProfileOpen } = useNavigationProfileContext();
  const { openDialog: openSubscriptionTierDialog } = useSubscriptionTierDialogContext();

  if (!user && isLoading) {
    return <LoadingSpinner className="grid size-full place-items-center" />;
  }

  const subscription = user?.subscription;
  const isEnterprise = subscription?.tier === SubscriptionTiers.ENTERPRISE;

  const handleOpenSubscriptionTierDialog = () => {
    openSubscriptionTierDialog();
    setNavigationProfileOpen({ open: false, section: null });
  };

  return (
    <>
      <div className="pb-6 text-lg font-bold">Account Activities</div>
      <div className="flex flex-wrap items-center justify-center gap-6 py-6 lg:flex-row">
        <div className="flex gap-6">
          <AccountActivitiesCard usage={subscription?.agents} header="Agents">
            <Icons.Bot className="size-6" />
          </AccountActivitiesCard>
          <AccountActivitiesCard usage={subscription?.messages} header="Messages">
            <Icons.Message className="size-6" />
          </AccountActivitiesCard>
        </div>
        <div className="flex gap-6">
          <AccountActivitiesCard usage={subscription?.knowledge.urls} header="Web Resources">
            <Icons.Link2 className="size-6" />
          </AccountActivitiesCard>
          <AccountActivitiesCard usage={subscription?.knowledge.docs} header="Documents">
            <Icons.Book className="size-6" />
          </AccountActivitiesCard>
        </div>
      </div>
      {isEnterprise ? (
        <AccountActivitiesUsersStats />
      ) : (
        <div className="m-2 mb-6 border-t-[1.5px]">
          <Card className="mt-6 flex flex-col items-center justify-center border-0  bg-gradient-to-b from-primary-50 to-stone-50">
            <CardHeader className="text-center text-xl font-bold">Boost your business with AgentX!</CardHeader>
            <CardDescription className="px-6 pb-5 text-center">
              enhanced AI capabilities, advanced customisation, priority support, expanded integration, insightful
              analytics and more
            </CardDescription>
            <CardContent className="text-center font-bold">supercharge your business today!</CardContent>
            <CardFooter className="flex flex-row items-center">
              <Button onClick={handleOpenSubscriptionTierDialog} variant="primary" className="w-32">
                Upgrade Now
              </Button>
            </CardFooter>
          </Card>
        </div>
      )}
    </>
  );
};
