import { Icons } from "@/components/ui/icons";
import { Table, TableBody, TableCell, TableHeader, TableRow } from "../../../../../ui/table";
import { useState } from "react";
import { cn } from "@/lib/utils";
import { useGetBillingPlans } from "@/data/queries/subscriptions/useGetBillingPlans";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { IconButton } from "@/components/ui/icon-button";

export const SubscriptionTierBenefits = ({ tableElementId }: { tableElementId: string }) => {
  const { data: plans, isPending } = useGetBillingPlans();
  const [currentColumnIndex, setCurrentColumnIndex] = useState<number>(0);
  const [animate, setAnimate] = useState<string>("");

  if (isPending) {
    return <LoadingSpinner className="flex justify-center pt-5" />;
  }

  if (!plans?.billingPlans?.length) {
    return null;
  }

  const handleColumnChange = (direction: "prev" | "next") => {
    setCurrentColumnIndex(prevColumnIndex => {
      const step = direction === "next" ? 1 : -1;
      const columnsCount = plans?.billingPlans?.length || 0;
      return (prevColumnIndex + step + columnsCount) % columnsCount;
    });
    setAnimate(direction === "next" ? "slide-in-from-right" : "slide-in-from-left");
  };

  return (
    <>
      <Table id={tableElementId} className="mb-10 mt-4 table-fixed text-sm">
        <TableHeader className="relative">
          <TableRow className="font-bold">
            <TableCell className="w-1/2 sm:w-[180px] md:w-[220px]"></TableCell>
            {plans?.billingPlans?.map((col, colIndex) => (
              <TableCell
                className={cn(
                  "hidden overflow-hidden rounded-md text-center text-2xl uppercase text-neutral-750 sm:table-cell",
                  {
                    "table-cell": currentColumnIndex === colIndex,
                  }
                )}
                key={col.name}
              >
                <div className="rounded-md px-6 py-4 shadow-card">
                  <span
                    className={cn(
                      animate,
                      "hidden text-nowrap text-base duration-500 animate-in sm:block lg:text-2xl",
                      {
                        block: currentColumnIndex === colIndex,
                      }
                    )}
                  >
                    {col.name}
                  </span>
                </div>
              </TableCell>
            ))}
          </TableRow>
          <div className="absolute left-1/2 top-0 flex h-full w-1/2 items-center justify-between sm:hidden">
            <IconButton
              variant="primary"
              variantColor="grey"
              onClick={() => handleColumnChange("prev")}
              icon={<Icons.ChevronLeft />}
            >
              <span className="sr-only">Previous plan</span>
            </IconButton>

            <IconButton
              variant="primary"
              variantColor="grey"
              onClick={() => handleColumnChange("next")}
              icon={<Icons.ChevronRight />}
            >
              <span className="sr-only">Next plan</span>
            </IconButton>
          </div>
        </TableHeader>
        <TableBody>
          {plans?.billingPlans[0].features.map((feature, index) => {
            return (
              <TableRow key={`feature-row-${index}`}>
                <TableCell className="break-normal text-xs text-neutral-750 sm:w-48 md:w-[225px]">
                  {feature.name}
                </TableCell>
                {plans?.billingPlans?.map((plan, planIndex) => {
                  const featureValue = plan.features[index].value;
                  return (
                    <TableCell
                      key={`${plan.name}-feature-${index}`}
                      className={cn("hidden overflow-hidden text-center text-xs text-neutral-750 sm:table-cell", {
                        "table-cell": currentColumnIndex === planIndex,
                      })}
                    >
                      <span
                        className={cn(animate, "hidden duration-500 animate-in sm:block", {
                          block: currentColumnIndex === planIndex,
                        })}
                      >
                        {featureValue ||
                          (plan.features[index].enabled ? (
                            <Icons.Check className="mx-auto text-success" />
                          ) : (
                            <Icons.XCircle className="mx-auto text-error" />
                          ))}
                      </span>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};
