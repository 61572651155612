import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
  DialogDescription,
} from "@/components/ui/dialog";
import { Icons } from "@/components/ui/icons";
import { SocialMediaShare } from "@/components/SocialMediaShare";
import { useShareConversation } from "@/data/mutations/useShareConversation";
import { Button } from "@/components/ui/button";
import { IconButton } from "@/components/ui/icon-button";

type ShareChatHistoryConversationButtonDialogProps = {
  conversationId: string;
  iconButton?: boolean;
};
export const ShareChatHistoryConversationButtonDialog = ({
  conversationId,
  iconButton,
}: ShareChatHistoryConversationButtonDialogProps) => {
  const { mutate: shareConversation } = useShareConversation();
  const shareLink = `${import.meta.env.VITE_BASE_FRONTEND_URL || "localhost:3001"}/share/conversation/${conversationId}`;

  const onShareClick = () => {
    shareConversation({ conversationId });
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        {iconButton ? (
          <IconButton
            variant="tertiary"
            className="p-0"
            icon={<Icons.Share className="size-5" />}
            onClick={onShareClick}
          />
        ) : (
          <Button
            variant="tertiary"
            className={`${iconButton ? "p-0.5" : "px-4 py-2 "}`}
            prefixIcon={<Icons.Share className="mr-3 size-4" />}
            onClick={onShareClick}
          >
            Share conversation
          </Button>
        )}
      </DialogTrigger>
      <DialogContent variant="small">
        <DialogHeader>Share Conversation</DialogHeader>
        <DialogDescription className="flex w-full flex-col items-center gap-6">
          <SocialMediaShare shareLink={shareLink} />
        </DialogDescription>

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="tertiary">Cancel</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
