import type { RefObject } from "react";
import { useEffect } from "react";

export const useAdaptiveScrollbar = (
  containerRef: RefObject<HTMLDivElement>,
  containerPaddingRight = 0 // value in px
) => {
  let scrollTimeout: NodeJS.Timeout | null = null;

  useEffect(() => {
    const scrollContainer = containerRef.current;
    if (!scrollContainer) {
      return;
    }

    scrollContainer.style.paddingRight = `${containerPaddingRight + 4}px`;

    scrollContainer.addEventListener("mouseenter", showScrollbar);
    scrollContainer.addEventListener("mousemove", showScrollbar);
    scrollContainer.addEventListener("scroll", showScrollbar);

    return () => {
      scrollContainer.removeEventListener("mouseenter", showScrollbar);
      scrollContainer.removeEventListener("scroll", showScrollbar);
      scrollContainer.removeEventListener("mousemove", showScrollbar);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showScrollbar = () => {
    const scrollContainer = containerRef.current;
    if (scrollContainer) {
      scrollContainer.classList.add("scrollbar-default");
      scrollContainer.style.paddingRight = `${containerPaddingRight - 2}px`;
      scrollContainer.onwheel = e => {
        e.stopPropagation();
      };
    }

    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    scrollTimeout = setTimeout(() => hideScrollbar(), 3000);
  };

  const hideScrollbar = () => {
    const scrollContainer = containerRef.current;
    if (scrollContainer) {
      scrollContainer.classList.remove("scrollbar-default");
      scrollContainer.style.paddingRight = `${containerPaddingRight + 4}px`;
    }
  };
};
