import { Avatar } from "@/components/Avatar";
import { Icons } from "@/components/ui/icons";
import { useGetCommunityUser } from "@/data/queries/useGetCommunityUser";
import { useNavigate, useParams } from "react-router-dom";
import type { DialogState } from "./FollowersDialog";
import { FollowersDialog } from "./FollowersDialog";
import type { CommunityUser } from "@/types/community";
import { useState } from "react";
import { useLikeUser } from "@/data/mutations/useLikeUser";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { useGetUser } from "@/data/queries/useGetUser";
import { IconButton } from "@/components/ui/icon-button";
import { Button } from "@/components/ui/button";

export const CommunityUserProfile = () => {
  const { profileId } = useParams<{ profileId: CommunityUser["_id"] }>();
  const { data: profile } = useGetCommunityUser({ userId: profileId });
  const { mutate: followOrUnfollowProfile, isPending } = useLikeUser();
  const [openedDialogTab, setOpenedDialogTab] = useState<DialogState>(null);
  const { user } = useGetUser();
  const navigate = useNavigate();

  const isMyProfile = user?._id === profileId;

  const handleFollowOrUnfollow = () => {
    followOrUnfollowProfile({ userId: profileId, status: !profile?.followedByUser });
  };
  return (
    <div className="relative flex w-full min-w-[300px] flex-col items-center gap-3 border-b border-neutral-300 py-6 sm:py-10 lg:w-[420px] lg:border-b-0 lg:border-r lg:pb-0 lg:pt-20">
      {!profile ? (
        <LoadingSpinner className="mt-10 sm:mt-16" />
      ) : (
        <>
          {!isMyProfile && (
            <IconButton
              variant="tertiary"
              onClick={() => navigate(-1)}
              className="absolute left-5 top-6"
              icon={<Icons.Arrow className="size-6" />}
            >
              <span className="sr-only">go back</span>
            </IconButton>
          )}
          <Avatar name={profile.name} src={profile?.avatar} size="l" />
          <div className="text-lg font-bold sm:pt-2 sm:text-xl">@{profile.name}</div>
          <div className="flex items-center gap-2 text-sm font-medium">
            <div className="cursor-pointer sm:py-2" onClick={() => setOpenedDialogTab("followers")}>
              <span className="font-semibold">{profile.followers} </span>Followers
            </div>
            <Icons.Spacer />
            <div className="cursor-pointer sm:py-2" onClick={() => setOpenedDialogTab("followings")}>
              <span className="font-semibold">{profile.following} </span>Followings
            </div>
          </div>
          {!isMyProfile && (
            <Button onClick={handleFollowOrUnfollow} loading={isPending}>
              {profile?.followedByUser ? "Unfollow" : "Follow"}
            </Button>
          )}
          {openedDialogTab && (
            <FollowersDialog
              profileId={profileId}
              openedDialogTab={openedDialogTab}
              setOpenedDialogTab={setOpenedDialogTab}
            />
          )}
        </>
      )}
    </div>
  );
};
