import { StripePaymentWrapper } from "@/components/dialogs/SubscriptionTierDialog/components/StripePaymentSummary/components/StripePaymentWrapper";
import type { BillingPlanCoupon } from "@/types/subscriptions";
import type { SelectedPlan } from "@/contexts/SubscriptionTierDialogContext/SubscriptionTierDialogProvider";

type StripePaymentOrderProps = {
  couponData: BillingPlanCoupon | undefined;
  selectedSeatsPrice: number;
  selectedSeats: number;
  selectedPlan: SelectedPlan;
  planPrice: string;
};

export const StripePaymentOrder = ({
  couponData,
  selectedSeats,
  selectedSeatsPrice,
  planPrice,
  selectedPlan,
}: StripePaymentOrderProps) => {
  return (
    <StripePaymentWrapper>
      <div className="flex w-full flex-col gap-4 divide-y">
        <div className="flex flex-col gap-2 text-xs">
          <div className="flex w-full justify-between font-bold capitalize">
            <span>
              {selectedPlan.period} {selectedPlan.plan.long_name}
            </span>
            <span>${planPrice}</span>
          </div>
          <div className="flex w-full justify-between font-bold capitalize">
            <span>{selectedSeats} Workspace seats</span>
            <span>${selectedSeatsPrice.toFixed(2)}</span>
          </div>
        </div>

        {couponData && (
          <div className="flex justify-between text-sm">
            {couponData.coupon}
            <span className="">- ${(couponData.originalPrice - couponData.discountPrice).toFixed(2)}</span>
          </div>
        )}
        <div>
          <div className="flex w-full justify-between pt-4 text-sm font-bold">
            <span>Total due</span>
            <span>
              ${((Number(couponData?.discountPrice.toFixed(2)) || Number(planPrice)) + selectedSeatsPrice).toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    </StripePaymentWrapper>
  );
};
