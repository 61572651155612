import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";

export type AccountUsagePricingCardProps = {
  title: string;
  price?: string;
  periodUnit?: string;
  btn?: {
    text: string;
    variant: "primary" | "secondary";
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
  };
  highlightText?: string;
  isHighlighted?: boolean;
  children: React.ReactNode;
  className?: string;
};

export const AccountUsagePricingCard = ({
  title,
  price,
  periodUnit,
  btn,
  highlightText,
  isHighlighted = false,
  className,
  children,
}: AccountUsagePricingCardProps) => {
  return (
    <Card
      className={cn(
        "relative mx-auto flex w-60 flex-col justify-between rounded-md border-2 border-x-2 border-transparent px-4 pb-4 pt-4 shadow-xl hover:bg-primary-100",
        {
          "!border-primary-400 bg-tier-pro": isHighlighted,
          "border-x-2 border-b-2 border-t-[20px]": !!highlightText,
        },
        className
      )}
    >
      {highlightText && (
        <p className="absolute -top-[1.125rem] left-1/2 -translate-x-1/2 text-[10px] font-medium leading-4 text-white">
          {highlightText}
        </p>
      )}
      <div>
        <h3 className="mb-4 w-fit bg-membership bg-clip-text text-xl text-transparent">{title}</h3>
        {price !== undefined && (
          <h4 className="mb-4 text-2xl font-medium">
            ${price}
            {!!periodUnit && <span className="text-xs font-medium leading-5 text-neutral-400">/month</span>}
          </h4>
        )}
        <h3 className="mb-4 w-fit bg-membership bg-clip-text text-xl text-transparent">
          {periodUnit == "year" && (
            <span>
              {price && !isNaN(Number(price)) && (
                <span>
                  ${Number(price) * 12}/{periodUnit}
                </span>
              )}
            </span>
          )}
        </h3>
        {children}
      </div>
      {btn && (
        <Button
          onClick={btn.onClick}
          variant={btn.variant}
          className={cn("mt-4 w-fit", btn.className)}
          disabled={btn.disabled}
        >
          {btn.text}
        </Button>
      )}
    </Card>
  );
};
