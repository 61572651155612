import { StripePaymentWrapper } from "@/components/dialogs/SubscriptionTierDialog/components/StripePaymentSummary/components/StripePaymentWrapper";
import type { PaymentPlans } from "@/types/subscriptions";
import { SubscriptionPeriod } from "@/types/subscriptions";
import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import { StripePaymentCategoryWrapper } from "@/components/dialogs/SubscriptionTierDialog/components/StripePaymentSummary/components/StripePaymentCategoryWrapper";
import type { SelectedPlan } from "@/contexts/SubscriptionTierDialogContext/SubscriptionTierDialogProvider";
import type { F1 } from "@/types/types";

type StripePaymentWorkspaceProps = {
  plans: PaymentPlans | undefined;
  selectedPlan: SelectedPlan;
  selectedSeats: number;
  setSelectedSeats: F1<number>;
  defaultSeats: number;
};

export const StripePaymentWorkspace = ({
  plans,
  selectedPlan,
  setSelectedSeats,
  selectedSeats,
  defaultSeats,
}: StripePaymentWorkspaceProps) => {
  const selectedPeriodPrice =
    plans?.userLicences?.find(license =>
      license.name.includes(selectedPlan.period === SubscriptionPeriod.MONTHLY ? selectedPlan.period : "annual")
    )?.price.amount || 0;
  const selectedPeriod = selectedPlan.period === SubscriptionPeriod.ANNUALLY ? "yearly" : "monthly";
  const includedSeats =
    selectedPlan.plan.features.find(feature => feature.name === "Team seats" && feature.enabled)?.value || 0;

  return (
    <StripePaymentCategoryWrapper title="Workspace">
      <StripePaymentWrapper>
        <div className="flex w-full flex-col">
          <div className="flex items-start justify-between">
            <div className="text-sm">How many seats do you need?</div>
            <div className="flex flex-col items-end gap-1 text-sm">
              <span>
                ${selectedPeriodPrice}/seat {selectedPeriod}
              </span>
              <span className="text-neutral-500">({includedSeats} free seats included)</span>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <IconButton
              variant="tertiary"
              icon={<Icons.Minus />}
              size="medium"
              disabled={selectedSeats === defaultSeats}
              onClick={() => setSelectedSeats(selectedSeats - 1)}
            />
            <div className="min-h-9 min-w-9 rounded-md border border-neutral-300 bg-white p-2 text-center text-sm font-semibold">
              {selectedSeats}
            </div>
            <IconButton
              variant="tertiary"
              icon={<Icons.Plus />}
              size="medium"
              onClick={() => setSelectedSeats(selectedSeats + 1)}
            />
          </div>
        </div>
      </StripePaymentWrapper>
    </StripePaymentCategoryWrapper>
  );
};
