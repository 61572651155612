import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  Dialog,
  DialogTrigger,
  DialogTitle,
  DialogClose,
} from "@/components/ui/dialog";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Icons } from "@/components/ui/icons";
import { StripePaymentForm } from "../SubscriptionTierDialog/components/StripePaymentSummary/components/StripePaymentForm";
import type { OnSubmitStripePlan } from "../SubscriptionTierDialog/components/StripePaymentSummary/StripePaymentSummary";
import { domElementIds } from "@/types/dom-element-ids";
import { useUpdatePaymentMethod } from "@/data/mutations/subscriptions/useUpdatePaymentMethod";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { IconButton } from "@/components/ui/icon-button";

export const EditBillingInformationDialog = () => {
  const [isDialogOpen, setShowDialog] = useState(false);
  const { mutate: updatePaymentMethod, isPending: isPaymentMethodUpdating } = useUpdatePaymentMethod();

  const onSubmit: OnSubmitStripePlan = ({ paymentMethodId }) => {
    updatePaymentMethod(
      { paymentMethodId },
      {
        onSuccess: () => {
          setShowDialog(false);
        },
      }
    );
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setShowDialog}>
      <Tooltip>
        <TooltipTrigger asChild>
          <DialogTrigger asChild>
            <IconButton variant="tertiary" size="tiny" icon={<Icons.Edit2 className="text-primary-400" />}>
              <span className="sr-only">Edit Credit Card info</span>
            </IconButton>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent>Edit Credit Card info</TooltipContent>
      </Tooltip>
      <DialogContent className="!h-auto max-h-dvh sm:min-h-min" variant="medium">
        <DialogHeader>
          <DialogTitle>Edit Billing Information</DialogTitle>
        </DialogHeader>
        <div className="overflow-y-auto px-8 pb-5">
          <StripePaymentForm onSubmit={onSubmit} />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="tertiary">Cancel</Button>
          </DialogClose>
          <Button loading={isPaymentMethodUpdating} type="submit" form={domElementIds.STRIPE_FORM}>
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
