export const convertHexToRgb = (hex: string | undefined) => {
  if (!hex) {
    return;
  }
  hex = hex.replace(/^#/, "");

  const bigint = parseInt(hex, 16);
  const red = (bigint >> 16) & 255;
  const green = (bigint >> 8) & 255;
  const blue = bigint & 255;

  return `${red} ${green} ${blue}`;
};
