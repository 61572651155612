import { AvatarFallback, AvatarContainer, AvatarImageRaw } from "@/components/ui/avatar";
import { Icons } from "./ui/icons";
import { cn } from "@/lib/utils";
import { avatarPathMap } from "@/utils/pickAvatar";
import type { F0 } from "@/types/types";
import type { ReactElement } from "react";

type Size = "l" | "xm" | "m" | "full" | "s" | "custom";

type AvatarProps = {
  src?: string;
  name?: string;
  size?: Size;
  className?: string;
  onClick?: F0;
  element?: ReactElement;
};

const sizesMap = {
  l: "size-28 sm:size-[180px]",
  xm: "h-[60px] w-[60px]",
  s: "h-[32px] w-[32px]",
  m: "h-10 w-10",
  full: "h-full w-full rounded-none rounded-t-lg",
  custom: "",
} satisfies Record<Size, string>;

export const Avatar = ({ src, size = "m", className, onClick, element }: AvatarProps) => {
  const avatarImg = src ? (
    <AvatarImageRaw src={avatarPathMap(src)} />
  ) : (
    <AvatarFallback className="flex size-full items-center justify-center bg-neutral-100 p-[20%] ">
      <Icons.User />
    </AvatarFallback>
  );

  return (
    <AvatarContainer className={cn("border-[0.75px] border-neutral-200", sizesMap[size], className)} onClick={onClick}>
      {element || avatarImg}
    </AvatarContainer>
  );
};
