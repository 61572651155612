import { externalLogin } from "@/utils/externalLogin";
import { Icons } from "../ui/icons";
import { FormInput } from "../ui/FormInput";
import { emailRegex } from "@/constants/regexps";
import { useNavigate } from "react-router";
import { ROUTES } from "@/constants/routes";
import { APP_LINKS } from "@/constants/appLinks";
import type { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Button } from "../ui/button";
import type { F1 } from "@/types/types";

type Props = {
  onSubmitForm: (data: { email: string; password: string; recaptchaToken?: string | undefined }) => void;
  isPendingSubmit: boolean;
  setShowDialog?: F1<boolean>;
  submitError: AxiosError<
    {
      message: string;
    },
    unknown
  > | null;
};

export const LoginForm = ({ onSubmitForm, isPendingSubmit, submitError, setShowDialog }: Props) => {
  const navigate = useNavigate();

  const form = useForm({ defaultValues: { email: "", password: "" } });
  const { handleSubmit } = form;

  const { executeRecaptcha } = useGoogleReCaptcha();

  const submitLogin = async (formData: { email: string; password: string }) => {
    const recaptchaToken = executeRecaptcha ? await executeRecaptcha("login") : undefined;
    const { email, password } = formData;

    onSubmitForm({
      email,
      password,
      recaptchaToken,
    });
  };

  return (
    <div>
      <Button
        className="w-full font-bold"
        variant="dark"
        size="medium"
        onClick={() => externalLogin("google")}
        prefixIcon={<Icons.Google />}
      >
        Continue with Google
      </Button>

      <div className="flex flex-1 items-center justify-between py-6">
        <Icons.Line />
        Or
        <Icons.Line />
      </div>

      <form onSubmit={handleSubmit(submitLogin)}>
        <FormInput
          formObj={form}
          name="email"
          autoComplete="email"
          required
          inputClassName="h-[50px]"
          placeholder="Enter email address"
          rules={{
            required: "Email is required",
            pattern: {
              value: emailRegex,
              message: "Entered value does not match email format",
            },
          }}
        />

        <FormInput
          formObj={form}
          showRevealPasswordButton
          name="password"
          autoComplete="current-password"
          placeholder="Enter password"
          containerClassName="mt-4"
          inputClassName="h-[50px]"
          required
          rules={{
            required: "Password is required",
            minLength: {
              value: 8,
              message: "Password must be at least 8 characters",
            },
          }}
        />
        <div className="flex w-full items-end">
          <p
            className="ml-auto cursor-pointer pb-4 pt-1 text-xs font-bold"
            onClick={() => navigate(ROUTES.forgotPassword)}
          >
            Forgot password?
          </p>
        </div>

        <Button type="submit" loading={isPendingSubmit} size="medium" className="w-full font-bold">
          Continue with Email
        </Button>
      </form>

      {submitError && (
        <div className="py-4 text-center text-xs font-medium leading-5 text-red-600">
          {submitError.response?.data?.message ===
          "Your request was blocked because the reCAPTCHA v3 verification failed. Please try again and ensure you are not flagged as a bot" ? (
            <div>{submitError.response?.data?.message}</div>
          ) : (
            <>
              <div>Sorry, your email or password is incorrect.</div>
              <div>Please double-check your email or password.</div>
            </>
          )}
        </div>
      )}

      <div className="text-xs font-bold leading-6">
        Don't have an account?{" "}
        <span
          className="cursor-pointer font-bold text-primary-500 underline"
          onClick={() => {
            setShowDialog?.(false);
            navigate(ROUTES.register);
          }}
        >
          Sign up Now
        </span>
      </div>

      <p className="pt-8 text-left text-xs font-bold text-neutral-400">
        By clicking “Continue”, I agree to AgentX{" "}
        <a
          href={APP_LINKS.termsOfService}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary-500 underline underline-offset-4"
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          href={APP_LINKS.privacy}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary-500 underline underline-offset-4"
        >
          Privacy Policy
        </a>
        . This site is protected by reCAPTCHA.
      </p>
    </div>
  );
};
