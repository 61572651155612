import * as React from "react";
import { Slot, Slottable } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { LoadingSpinner } from "./loading-spinner";

const defaultVariants = {
  variant: "primary",
  variantColor: "purple",
  size: "small",
  roundedType: "square",
  contentType: "default",
} as const;

const buttonVariants = cva(
  "relative inline-flex items-center justify-center whitespace-nowrap font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-1 disabled:pointer-events-none dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300",
  {
    variants: {
      variant: {
        primary:
          "bg-primary-400 text-white border border-primary-400 hover:bg-primary-600 hover:border-primary-600 disabled:bg-primary-200 disabled:border-primary-200 active:bg-primary-500 active:border-primary-500 focus-visible:ring-primary-400",
        secondary:
          "bg-primary-50 text-primary-400 border border-primary-400 hover:bg-primary-100 hover:border-primary-500 hover:text-primary-600 disabled:bg-primary-50 disabled:border-primary-50 disabled:text-primary-200 active:bg-primary-200 active:border-primary-500 active:text-primary-400 focus-visible:ring-primary-400",
        tertiary:
          "bg-white text-neutral-750 border border-neutral-400 hover:bg-neutral-200 hover:border-neutral-400 disabled:bg-neutral-50 disabled:border-white disabled:text-neutral-400 active:bg-neutral-300 active:border-neutral-100 active:text-neutral-750 focus-visible:ring-neutral-400",
        danger:
          "bg-red-500 text-red-50 hover:bg-red-500/90 dark:bg-red-50 dark:text-red-500 dark:hover:bg-red-50/90 disabled:bg-opacity-50 disabled:bg-red-500 disabled:text-red-50 active:bg-red-600 active:text-red-50 focus-visible:ring-red-500",
        dark: "bg-black text-white hover:bg-gray-800",
        ghost: "hover:bg-accent hover:text-accent-foreground text-neutral-750 disabled:opacity-50",
      },
      size: {
        tiny: "text-xs px-4 py-0 leading-5 min-h-[28px]",
        small: "text-xs px-6 py-2 leading-5",
        medium: "text-sm px-6 py-2 leading-[22px]",
        custom: "",
      },
      roundedType: {
        rounded: "rounded-full",
        square: "rounded-md",
      },
    },
    compoundVariants: [
      {
        variant: "tertiary",
        size: "tiny",
        class:
          "border-none bg-transparent disabled:bg-transparent focus-visible:ring-offset-transparent focus-visible:ring-offset-0",
      },
      {
        variant: "tertiary",
        size: "tiny",
        roundedType: "rounded",
        class: "bg-primary-50 hover:bg-primary-200 text-primary-400",
      },
    ],
    defaultVariants,
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  icon?: React.ReactElement<{ className?: string }>;
  prefixIcon?: React.ReactElement<{ className?: string }>;
  suffixIcon?: React.ReactElement<{ className?: string }>;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, roundedType, loading = false, asChild = false, prefixIcon, suffixIcon, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";

    const buttonProps = {
      type: props.type ?? "button",
      disabled: loading || props.disabled,
    };

    return (
      <Comp
        className={cn(
          buttonVariants({
            variant,
            size,
            roundedType,
            className,
          }),
          {
            "text-transparent": loading,
          }
        )}
        ref={ref}
        {...(!asChild && { ...buttonProps })}
        {...props}
      >
        <Slottable>
          <>
            {prefixIcon &&
              React.cloneElement(prefixIcon, { className: cn("mr-2 w-4 h-4", prefixIcon.props.className) })}
            {props.children}
            {suffixIcon &&
              React.cloneElement(suffixIcon, { className: cn("ml-2 w-4 h-4", suffixIcon.props.className) })}
          </>
        </Slottable>
        {loading && <LoadingSpinner className="absolute-center" />}
      </Comp>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
