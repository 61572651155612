import { cn } from "@/lib/utils";
import StatsDataTitle from "./common/StatsDataTitle";
import StatsTrendIndicator from "./common/StatsTrendIndicator";
import StatsDataTrendChart from "./common/StatsDataTrendChart";
import { StatsAnimatedValue } from "./common/StatsAnimatedValue/StatsAnimatedValue";
import { useEffect, useRef, useState } from "react";
import { StatsBlur } from "./common/StatsBlur";

type Props = {
  className?: string;
  title: string;
  value: number;
  trend?: number | null;
  icon: React.ReactNode;
  blur?: boolean;
};

const StatsTopCard = ({ className, title, value, trend, icon, blur }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    const updateDimensions = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };

    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div
      ref={ref}
      className={cn(
        "relative h-full grow rounded-xl border border-neutral-50 bg-bg-light p-3 @container/card md:px-7 md:py-6",
        className
      )}
    >
      {blur && (
        <StatsBlur className="rounded-xl p-3 md:px-7 md:py-6">
          <StatsDataTitle title={title} />
        </StatsBlur>
      )}

      <div className="relative flex items-center justify-between gap-1 @sm/card:gap-6">
        <div>
          <StatsDataTitle
            title={title}
            className={cn({
              "opacity-0": blur,
            })}
          />
          <StatsAnimatedValue value={value} />
        </div>

        {!!trend && <StatsDataTrendChart trend={trend} parentWidth={width} maxWidth={90} />}
        <div className="rounded-[4px] bg-white p-1 shadow-sm sm:p-2.5 md:rounded-xl">{icon}</div>
      </div>

      <StatsTrendIndicator trend={trend} />
    </div>
  );
};

export default StatsTopCard;
