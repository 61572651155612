import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTrigger } from "@/components/ui/dialog";
import { Icons } from "@/components/ui/icons";
import { useState } from "react";
import { useLeaveWorkspace } from "@/data/mutations/workspace/useLeaveWorkspace";
import { Button } from "@/components/ui/button";

export const LeaveWorkspaceMemberDialog = () => {
  const [isDialogOpen, setShowDialog] = useState(false);
  const { mutate: leaveWorkspace, isPending: isUserLeavingPending } = useLeaveWorkspace();
  const handleLeaveFromWorkspace = () => {
    leaveWorkspace();
    setShowDialog(false);
  };
  return (
    <Dialog onOpenChange={setShowDialog} open={isDialogOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" className="w-full items-center justify-start gap-2 p-0 text-error focus:text-error">
          <span className="text-error">Leave workspace</span>
        </Button>
      </DialogTrigger>

      <DialogContent variant="medium">
        <DialogHeader></DialogHeader>
        <div className="mx-auto flex max-w-[446px] flex-col items-center gap-2.5 px-8 pb-5 text-center">
          <Icons.LeaveWorkspace />
          <div className="text-xl font-bold">Are you sure you want to Leave this workspace?</div>
          <p className="text-sm text-neutral-400">
            Do you really want to leave this workspace Please note this action cannot be undone.
          </p>
        </div>
        <DialogFooter>
          <Button loading={isUserLeavingPending} onClick={handleLeaveFromWorkspace} variant="primary">
            Leave
          </Button>
          <DialogClose asChild>
            <Button variant="tertiary">Cancel</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
