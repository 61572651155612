import * as React from "react";
import { Slot, Slottable } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { LoadingSpinner } from "./loading-spinner";

const defaultVariants = {
  size: "md",
  emphasis: "primary",
} as const;

const linkButtonVariants = cva(
  "relative inline-flex items-center justify-center whitespace-nowrap ring-offset-white transition-colors hover:underline disabled:underline focus-visible:underline underline-offset-4 focus-visible:outline-none disabled:pointer-events-none",
  {
    variants: {
      size: {
        sm: "text-xxs leading-4 font-medium",
        md: "text-xs leading-5 font-medium",
        lg: "text-sm leading-[22px] font-medium",
        huge: "text-xl leading-[26px] font-bold",
        custom: "",
      },
      emphasis: {
        bold: "text-neutral-750 disabled:text-neutral-300",
        primary: "text-neutral-750 disabled:text-neutral-300",
        secondary: "text-neutral-400 disabled:text-neutral-200",
      },
    },
    compoundVariants: [
      {
        size: "md",
        emphasis: "bold",
        class: "font-bold",
      },
      {
        size: "lg",
        emphasis: "bold",
        class: "font-bold",
      },
    ],
    defaultVariants,
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof linkButtonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const LinkButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, size, emphasis, loading = false, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";

    const buttonProps = {
      type: props.type ?? "button",
      disabled: loading || props.disabled,
    };

    return (
      <Comp
        className={cn(
          linkButtonVariants({
            size,
            emphasis,
            className,
          }),
          {
            "text-transparent": loading,
          }
        )}
        ref={ref}
        {...(!asChild && { ...buttonProps })}
        {...props}
      >
        <Slottable>{props.children}</Slottable>
        {loading && <LoadingSpinner className="leading absolute-center" />}
      </Comp>
    );
  }
);
LinkButton.displayName = "Button";

export { LinkButton, linkButtonVariants };
