import { useState } from "react";
import { BookmarkList } from "./components/BookmarkList";
import { ChatHistory } from "./components/ChatHistory";
import { cn } from "@/lib/utils";
import { Icons } from "@/components/ui/icons";
import { Button } from "../ui/button";

const tabs = ["Bookmarks", "Chat History"] as const;
type Tabs = (typeof tabs)[number];

type BookmarksHistoryPanelProps = {
  className?: string;
  onClosePanel?: () => void;
};

const tabComponents: { [K in Tabs]: React.ComponentType<Pick<BookmarksHistoryPanelProps, "onClosePanel">> } = {
  Bookmarks: BookmarkList,
  "Chat History": ChatHistory,
};

const BookmarksHistoryPanel = ({ className, onClosePanel }: BookmarksHistoryPanelProps) => {
  const [activeTab, setActiveTab] = useState<Tabs>("Chat History");
  const ActiveTabComponent = tabComponents[activeTab];

  return (
    <div
      className={cn(
        "z-50 h-full w-full bg-[#1F0E3D] lg:min-w-[20rem] lg:max-w-[20rem] min-[1150px]:max-w-[24rem] xl:min-w-[32rem] xl:max-w-[32rem]",
        className
      )}
    >
      <div className="flex items-center gap-3 px-6 pb-0 pt-4">
        <Button variant="ghost" size="custom" onClick={onClosePanel} className="lg:hidden">
          <Icons.Arrow className="size-6 text-white" />
          <span className="sr-only">go back</span>
        </Button>

        {tabs.map(tab => (
          <div
            key={tab}
            className={cn("cursor-pointer whitespace-nowrap border-b-2 border-transparent text-xl text-white", {
              "border-white font-bold": activeTab === tab,
            })}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </div>
        ))}
      </div>
      <ActiveTabComponent onClosePanel={onClosePanel} />
    </div>
  );
};

export default BookmarksHistoryPanel;
