import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogDescription,
} from "@/components/ui/dialog";
import DeleteBin from "@/assets/images/DeleteBin.svg";
import type { Conversation } from "@/types/conversation";
import { useDeleteConversation } from "@/data/mutations/useDeleteConversation";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { useNavigate } from "react-router-dom";
import type { F1 } from "@/types/types";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import { ROUTES } from "@/constants/routes";
import { LocalStorageHelpers } from "@/utils/LocalStorageHelpers";
import { Button } from "@/components/ui/button";

type DeleteChatDialogProps = {
  conversationIdToDelete: Conversation["_id"] | null;
  setConversationIdToDelete: F1<Conversation["_id"] | null>;
  isDialogOpen: boolean;
  setShowDialog: F1<boolean>;
};

export const DeleteChatDialog = ({
  conversationIdToDelete,
  setConversationIdToDelete,
  isDialogOpen,
  setShowDialog,
}: DeleteChatDialogProps) => {
  const { conversationId, conversationDetails } = useChatContext();

  const navigate = useNavigate();

  const { mutate, isPending } = useDeleteConversation();

  const deleteConversation = () => {
    if (!conversationIdToDelete) {
      return;
    }

    mutate(conversationIdToDelete, {
      onSuccess: () => {
        if (conversationId === conversationIdToDelete) {
          const currentAgentIds = conversationDetails?.bots.map(bot => bot._id);

          if (!currentAgentIds) {
            navigate(ROUTES.workspace);
          } else {
            LocalStorageHelpers.removeAgentsLocalStorageConversation(currentAgentIds);
            navigate(getNewConversationRoute(currentAgentIds));
          }
        }
        setConversationIdToDelete(null);
        setShowDialog(false);
      },
    });
  };

  const toggleDialog = (open: boolean) => {
    setShowDialog(open);
    setConversationIdToDelete(null);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={toggleDialog}>
      <DialogContent variant="medium" className="h-[350px]">
        <DialogHeader></DialogHeader>
        <DialogDescription className="flex flex-col items-center gap-2.5">
          <img src={DeleteBin} alt="Delete Bin" className="w-20" />
          <div className="text-xl font-bold">Are you sure you want to Delete this chat?</div>
          <div className="text-center text-sm text-neutral-400">
            Do you really want to delete this Chat? Please note this action cannot be undone.
          </div>
        </DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button size="medium" variant="secondary">
              Cancel
            </Button>
          </DialogClose>
          <Button size="medium" variant="danger" onClick={deleteConversation} loading={isPending}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
