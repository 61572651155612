import { Icons } from "@/components/ui/icons";
import type { Deployments } from "@/types/user-agent";
import { HybridTooltipPopover } from "../HybridTooltipPopover";

type AgentDeploymentsIconsProps = {
  deployments: Deployments[];
};

const deploymentIconsData = {
  DISCORD: {
    icon: <Icons.Discord className="size-6 text-[#5865F2]" />,
    description: "Discord",
  },
  SLACK: {
    icon: <Icons.Slack className="size-5 text-[#5865F2]" />,
    description: "Slack",
  },
  COMMUNITY: {
    icon: <Icons.Community className="size-5 text-primary-400" />,
    description: "Community",
  },
  EMBED: {
    icon: <Icons.Globe className="size-6" />,
    description: "Website Embedding",
  },
  WHATSAPP: {
    icon: <Icons.WhatsUp className="size-6 text-[#25D366]" />,
    description: "WhatsApp",
  },
  TXT_MESSAGE: {
    icon: <Icons.Text className="size-6" />,
    description: "Text Messages",
  },
};

export const AgentDeploymentsIcons = ({ deployments }: AgentDeploymentsIconsProps) => {
  return (
    <div
      className="flex items-center gap-2"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {deployments.map(deployment => {
        const deploymentItem = deploymentIconsData[deployment];

        return (
          <HybridTooltipPopover key={deployment} triggerComponent={deploymentItem?.icon}>
            {deploymentItem?.description}
          </HybridTooltipPopover>
        );
      })}
    </div>
  );
};
