import { useState } from "react";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onRenameSubmit: (name: string) => void;
  isSubmitLoading?: boolean;
}

const RenameConversationDialog = ({ isOpen, setIsOpen, onRenameSubmit, isSubmitLoading }: Props) => {
  const [newName, setNewName] = useState("");

  const handleRename = () => {
    onRenameSubmit(newName);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="!h-fit !w-fit min-w-80">
        <DialogHeader className=" pl-4">Rename this conversation</DialogHeader>
        <div className="w-full px-4 pb-12 pt-4">
          <Input value={newName} onChange={e => setNewName(e.target.value)} inputClassName="w-full" />
        </div>

        <DialogFooter className="flex justify-end gap-2 px-4">
          <Button onClick={() => setIsOpen(false)} variant="tertiary">
            Cancel
          </Button>
          <Button onClick={handleRename} loading={isSubmitLoading}>
            OK
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default RenameConversationDialog;
