import { useState } from "react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import type { UseFormReturn } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import { Icons } from "@/components/ui/icons";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import type { Parameter, Tool } from "@/types/tools";
import { Input } from "@/components/ui/input";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { Button } from "@/components/ui/button";
import { IconButton } from "@/components/ui/icon-button";

type CustomisedParametersProps = {
  form: UseFormReturn<Tool>;
};

export const CustomisedParameters = ({ form }: CustomisedParametersProps) => {
  const [accordionValueOpen, setAccordionValueOpen] = useState("");

  const {
    fields: parameters,
    append,
    remove,
    update,
  } = useFieldArray({
    control: form.control,
    name: "parameters",
  });

  const addParameter = () => {
    append({
      parameterName: "",
      parameterDescription: "",
      required: false,
      type: "string",
      _id: "",
    });
  };

  const removeParameter = (index: number) => {
    remove(index);
  };

  const updateField = <Key extends keyof Parameter, Value extends Parameter[Key]>(
    index: number,
    field: Key,
    value: Value
  ) => {
    const newParameter = { ...parameters[index], [field]: value };
    update(index, newParameter);
  };

  return (
    <Accordion
      type="single"
      collapsible
      className="mt-2 rounded-md border border-neutral-300"
      value={accordionValueOpen}
      onValueChange={setAccordionValueOpen}
    >
      <AccordionItem value="customised">
        <AccordionTrigger className="px-4 text-xs font-medium text-neutral-800">
          <div className="flex h-4 min-w-[16px] items-center justify-center rounded-full bg-primary-500 px-1 text-[10px] font-medium leading-none text-white">
            {parameters.length || 0}
          </div>
          <div className="ml-2 mr-auto flex gap-1 text-sm">
            <div>Parameters</div>
            <HybridTooltipPopover>
              <p>Please provide the parameter details</p>
            </HybridTooltipPopover>
          </div>
        </AccordionTrigger>
        <AccordionContent className="flex flex-col gap-2 rounded-b-md bg-neutral-50 p-2">
          {parameters?.map((parameter, index) => (
            <div
              key={parameter._id}
              className="flex w-full items-start justify-between gap-5 overflow-x-scroll rounded-md border border-neutral-200 bg-white px-4 py-3"
            >
              <div className="flex flex-col gap-2">
                <Input
                  label="Parameter Name"
                  name="parameterName"
                  value={parameter.parameterName}
                  onChange={e => updateField(index, "parameterName", e.target.value)}
                  placeholder="write a name"
                  inputClassName="h-10"
                  required
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <Label htmlFor="parameterType">Type</Label>
                <Select onValueChange={e => updateField(index, "type", e as Parameter["type"])} value={parameter.type}>
                  <SelectTrigger id="parameterType" className="w-full">
                    <SelectValue placeholder="Select"></SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="string">string</SelectItem>
                    <SelectItem value="integer">integer</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <Input
                label="Description"
                name="description"
                value={parameter.parameterDescription}
                onChange={e => updateField(index, "parameterDescription", e.target.value)}
                placeholder="write a description"
                inputClassName="h-10"
                required
              />
              <div className="flex flex-col gap-1.5">
                <Label htmlFor="parameterRequired">Required</Label>
                <Switch
                  id="parameterRequired"
                  className="mt-2"
                  checked={parameter.required}
                  onCheckedChange={e => updateField(index, "required", e)}
                />
              </div>

              <IconButton
                type="button"
                className="ml-2 mt-[30px]"
                variant="tertiary"
                size="small"
                onClick={() => removeParameter(index)}
                icon={<Icons.Trash className="text-neutral-400" />}
              />
            </div>
          ))}
          <div className="flex items-center justify-center">
            <Button onClick={addParameter}>Add parameter</Button>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
