import { Dialog, DialogContent } from "@/components/ui/dialog";
import { SubscriptionTiers } from "./components/SubscriptionTiers/SubscriptionTiers";
import { StripePaymentSummary } from "./components/StripePaymentSummary/StripePaymentSummary";
import { StripePaymentSuccess } from "./components/StripePaymentSummary/components/StripePaymentSuccess";
import { ChangeSubscriptionTier } from "./components/ChangeSubscriptionTier/ChangeSubscriptionTier";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";

const subscriptionDialogSteps = [
  "subscriptionTiers",
  "stripePaymentSummary",
  "stripePaymentSuccess",
  "changeSubscriptionTier",
] as const;
export type SubscriptionDialogSteps = (typeof subscriptionDialogSteps)[number];
const subscriptionDialogComponents: {
  [K in SubscriptionDialogSteps]: React.ComponentType;
} = {
  subscriptionTiers: SubscriptionTiers,
  stripePaymentSummary: StripePaymentSummary,
  stripePaymentSuccess: StripePaymentSuccess,
  changeSubscriptionTier: ChangeSubscriptionTier,
} as const;

export const SubscriptionTierDialog = () => {
  const { closeDialog, isOpen, activeStep, isPendingPayment } = useSubscriptionTierDialogContext();
  const ActiveStepComponent = subscriptionDialogComponents[activeStep];
  const handleToggleOpen = (isOpen: boolean) => {
    if (!isOpen) {
      closeDialog();
    }
  };
  return (
    <Dialog open={isOpen} onOpenChange={isPendingPayment ? undefined : handleToggleOpen}>
      <DialogContent variant="large" className="gap-0 pb-0 lg:max-h-screen">
        <ActiveStepComponent />
      </DialogContent>
    </Dialog>
  );
};
