import { mapStatOriginToOrigin, type StatOrigin } from "@/types/origin";
import { channelsLegendData } from "../../constants/statsConstants";
import { Icons } from "@/components/ui/icons";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import type { Agent } from "@/types/agent";
import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { LinkButton } from "@/components/ui/link-button";

export const StatsSingleAgentRowDetails = ({
  channels,
  agent,
}: {
  channels: {
    channel: StatOrigin;
    value: number;
  }[];
  agent: Agent;
}) => {
  const { setChatHistoryDialogProps } = useStatsContext();

  const getChannelValue = (channel: StatOrigin) => {
    return channels.find(channelItem => channelItem.channel === channel)?.value ?? 0;
  };

  const totalValue = channels.reduce((acc, channel) => acc + channel.value, 0);

  const getPercentageValue = (channel: StatOrigin) => {
    if (totalValue === 0) {
      return 0;
    }

    return ((getChannelValue(channel) / totalValue) * 100).toFixed(2);
  };

  const displayBars = !!totalValue;

  return (
    <div className="ml-2 grid grid-cols-[1fr_auto] gap-x-6 gap-y-4">
      {displayBars && (
        <div className="relative col-span-2 flex w-full items-center gap-[1px] md:col-span-1">
          {channelsLegendData.map(item => {
            return (
              <Tooltip key={item.channel}>
                <TooltipTrigger asChild>
                  <div
                    key={item.channel}
                    className="h-[38px] rounded-sm"
                    style={{
                      width: `${getPercentageValue(item.channel)}%`,
                      backgroundColor: item.color,
                    }}
                  />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent
                    align="center"
                    side="bottom"
                    sideOffset={-10}
                    className="border border-neutral-200 bg-white"
                  >
                    <div>
                      <div className="flex items-center gap-2">
                        <div
                          className="h-2 w-2 rounded-full"
                          style={{
                            backgroundColor: item.color,
                          }}
                        />
                        <p className="text-sm font-medium text-neutral-750">{item.label}</p>
                        <span className="text-sm font-medium leading-[22px] text-neutral-400">{`${getPercentageValue(item.channel)}%`}</span>
                      </div>

                      <LinkButton
                        size="md"
                        onClick={() => {
                          setChatHistoryDialogProps({ agent, channel: mapStatOriginToOrigin(item.channel) });
                        }}
                        className="flex items-center justify-between gap-1 leading-[22px] text-primary-400"
                      >
                        {`${item.label} Conversations`}
                        <Icons.Arrow className="size-4 rotate-[135deg] text-primary-400" />
                      </LinkButton>
                    </div>
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            );
          })}
        </div>
      )}

      <Button
        variant="secondary"
        className="row-start-3 w-fit place-self-center md:row-start-auto"
        onClick={() => setChatHistoryDialogProps({ agent })}
        suffixIcon={<Icons.Arrow className="size-4 rotate-180" />}
      >
        Conversations
      </Button>

      <div className="col-span-2 mt-4 flex flex-wrap gap-4">
        {channelsLegendData.map(item => {
          return (
            <div key={item.channel} className="flex items-center gap-2">
              <div
                className="h-2 w-2 rounded-full"
                style={{
                  backgroundColor: item.color,
                }}
              />
              <p className="text-sm font-medium text-neutral-750">{item.label}</p>
              <span className="text-sm font-medium leading-[22px] text-neutral-400">{`${getPercentageValue(item.channel)}%`}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
