import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from "@/components/ui/dialog";
import type { Agent } from "@/types/agent";
import { usePublishAgentToCommunity } from "@/data/mutations/usePublishAgentToCommunity";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { useState, type ReactElement } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { singlePrivateAgentWithConfigKeys } from "@/data/queries/useGetSinglePrivateAgentWithConfig";
import { Button } from "@/components/ui/button";

type UnpublishAgentDialogProps = {
  agentId: Agent["_id"];
  renderButton: () => ReactElement;
};

export const UnpublishAgentDialog = ({ agentId, renderButton }: UnpublishAgentDialogProps) => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = usePublishAgentToCommunity();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const unpublishCommunityAgent = () => {
    mutate(
      {
        agentId,
        status: false,
      },
      {
        onSettled: () => {
          setIsDialogOpen(false);
        },
        onSuccess: () => {
          void queryClient.invalidateQueries({ queryKey: singlePrivateAgentWithConfigKeys.id(agentId) });
        },
      }
    );
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>{renderButton()}</DialogTrigger>
      <DialogContent variant="medium">
        <DialogHeader></DialogHeader>
        <DialogDescription className="flex flex-col gap-2.5 text-center">
          <div className="mt-auto translate-y-[-18px] scale-[4]">😢</div>
          <div className="text-xl font-bold">Are you sure you want to unpublish?</div>
          <div className="mb-auto text-center text-sm text-neutral-400">
            Our community page helps to boost the viewership of your great idea. Publish now and watch your impact grow!
          </div>
        </DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button onClick={unpublishCommunityAgent} loading={isPending}>
            Unpublish
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
