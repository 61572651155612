import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogClose,
  DialogDescription,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Icons } from "@/components/ui/icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useDeleteExternalAgent } from "@/data/mutations/external-agents/useDeleteExternalAgent";
import { useState } from "react";

type DeleteExternalAgentDialogProps = {
  externalAgentId: string;
  platform: string;
};
export const DeleteExternalAgentDialog = ({ externalAgentId, platform }: DeleteExternalAgentDialogProps) => {
  const { mutate: deleteExternalAgent, isPending: isDeleting } = useDeleteExternalAgent();
  const [isDialogOpen, setShowDialog] = useState(false);
  const { getValues: getAgentFormValues } = useAgentFormContext();
  const agentId = getAgentFormValues("_id");
  const handleAgentDeletion = () => {
    deleteExternalAgent({ externalAgentId, agentId }, { onSuccess: () => setShowDialog(false) });
  };
  return (
    <Dialog open={isDialogOpen} onOpenChange={setShowDialog}>
      <Tooltip>
        <TooltipTrigger asChild>
          <DialogTrigger>
            <Icons.Trash className="text-primary-400" />
            <span className="sr-only">Delete {platform} Connection</span>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent side="bottom">Delete {platform} Connection</TooltipContent>
      </Tooltip>
      <DialogContent variant="small">
        <DialogHeader>Delete {platform} Connection</DialogHeader>
        <DialogDescription className="pt-0">
          You can always start a new integration after.
          <div className="mt-5 font-medium">Are you sure you want to delete your {platform} Connection?</div>
        </DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button variant="danger" loading={isDeleting} onClick={handleAgentDeletion}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
