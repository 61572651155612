import { useNavigate } from "react-router";
import { ROUTES } from "@/constants/routes";
import { Button } from "@/components/ui/button";

export const RegisterAgeVerificationFailed = () => {
  const navigate = useNavigate();

  return (
    <>
      <h2 className="mb-8 text-center text-2xl font-bold leading-9 tracking-tight lg:text-[28px]">
        Sorry, you can’t access AgentX
      </h2>
      <p className="mx-auto mb-10 w-[240px] text-center text-sm font-medium leading-[170%] text-primary-black">
        Access is not granted to individuals under the age of 13.
      </p>
      <Button variant="primary" className="w-full" onClick={() => navigate(ROUTES.login)}>
        Back to Login
      </Button>
    </>
  );
};
