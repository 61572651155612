import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTrigger } from "@/components/ui/dialog";
import { useDeleteAccount } from "@/data/mutations/useDeleteAccount";
import { DialogDescription } from "@radix-ui/react-dialog";
import { Input } from "../ui/input";
import { useGetUser } from "@/data/queries/useGetUser";
import { useState } from "react";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { Button } from "../ui/button";

export const DeleteAccount = () => {
  const { user } = useGetUser();
  const { mutate } = useDeleteAccount();
  const { setNavigationProfileOpen } = useNavigationProfileContext();
  const [email, setEmail] = useState<string>("");
  return (
    <>
      <div className="mt-10 border-t-[1.5px] py-6 text-lg font-bold">Account</div>
      <div className="mb-10 flex items-center justify-between">
        <div className="text-sm"></div>
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="danger">Delete account</Button>
          </DialogTrigger>
          <DialogContent variant="small">
            <DialogHeader>Delete account</DialogHeader>
            <div className="px-5">
              <DialogDescription className="text-sm">
                This action cannot be undone. It will permanently delete your entire account. Please type in your email
                to confirm.
              </DialogDescription>
              <Input
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder={user?.email}
                inputClassName="mt-4"
              />
            </div>
            <DialogFooter>
              <DialogClose asChild>
                <Button size="medium" variant="tertiary">
                  Cancel
                </Button>
              </DialogClose>
              <Button
                size="medium"
                disabled={email !== user?.email}
                variant="danger"
                onClick={() =>
                  mutate(undefined, { onSuccess: () => setNavigationProfileOpen({ open: false, section: null }) })
                }
              >
                Confirm Delete
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};
