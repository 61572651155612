import { HelpForm } from "./components/HelpForm";
import { WhatsNew } from "./components/WhatsNew";

export const Help = () => {
  return (
    <div className="flex flex-1 flex-col gap-10 overflow-y-auto lg:flex-row">
      <div className="grid size-full place-items-center py-10 lg:w-2/3 lg:px-6">
        <HelpForm />
      </div>
      <WhatsNew />
    </div>
  );
};
