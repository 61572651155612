import { userKeys } from "@/data/queries/useGetUser";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError, AxiosResponse } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { toast } from "react-toastify";
import { customerBillingInfoKeys } from "@/data/queries/subscriptions/useGetCustomerBillingInfo";
import { workspaceDetailsKeys } from "@/data/queries/workspace/useGetWorkspaceDetails";

type Body = {
  targetPlanId: string;
  quantity?: number;
};
const updateSubscriptionPlan = async (payload: Body) => {
  const { data } = await restClient.post<unknown, AxiosResponse<unknown>, Body>(
    apiPaths.updateSubscriptionPlan,
    payload
  );
  return data;
};

export const useUpdateSubscriptionPlan = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, AxiosError<{ message: string }>, Body>({
    mutationFn: updateSubscriptionPlan,
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: userKeys.all }),
        queryClient.invalidateQueries({ queryKey: customerBillingInfoKeys.all }),
        queryClient.invalidateQueries({ queryKey: workspaceDetailsKeys.all }),
      ]);
      toast.success("Subscription changed successfully");
    },
  });
};
