import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { AgentOverviewStats, StatsQueryFilters } from "@/types/stats";
import { StatisticsHelpers } from "@/utils/statistics/StatisticsHelpers";

export const agentsOverviewStats = {
  all: ["agentsOverviewStats"] as const,
  params: ({ startDateParam, endDateParam }: { startDateParam: string; endDateParam: string }) =>
    [...agentsOverviewStats.all, startDateParam, endDateParam] as const,
};

const getAgentsOverviewStats = async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
  const { data } = await restClient.get<AgentOverviewStats[]>(apiPaths.getAgentsOverviewStats, {
    params: {
      startDate,
      endDate,
      timezone: StatisticsHelpers.getTimezone(),
    },
  });
  return data;
};

type Props = Omit<StatsQueryFilters, "channels">;

export const useGetAgentsOverviewStats = (props: Props) => {
  const { currentRange, startDate, endDate, enabled = true } = props;

  const startDateParam = StatisticsHelpers.getStartDateParam({ range: currentRange, startDate }) ?? "";
  const endDateParam = StatisticsHelpers.getEndDateParam({ range: currentRange, endDate }) ?? "";

  const query = useQuery({
    queryKey: agentsOverviewStats.params({ startDateParam, endDateParam }),
    queryFn: () => getAgentsOverviewStats({ startDate: startDateParam, endDate: endDateParam }),
    enabled: !!startDateParam && !!endDateParam && enabled,
    placeholderData: keepPreviousData,
  });

  return query;
};
