import { ROUTES } from "@/constants/routes";
import { useNavigate } from "react-router";
import { Controller, get, type UseFormReturn } from "react-hook-form";
import { useRegisterUser } from "@/data/mutations/useRegisterUser";
import type { RegisterFormTypes } from "@/types/user-register";
import { RegisterSuccess } from "@/components/RegisterForm/components/RegisterSuccess";
import { pickUserAvatar } from "@/utils/pickAvatar";
import { externalLogin } from "@/utils/externalLogin";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Datepicker from "@/components/Datepicker/Datepicker";
import { subYears } from "date-fns";
import { Button } from "@/components/ui/button";

type RegisterAgeVerificationProps = {
  form: UseFormReturn<RegisterFormTypes>;
  isGoogleRegister: boolean;
};

export const RegisterAgeVerification = ({ form, isGoogleRegister }: RegisterAgeVerificationProps) => {
  const navigate = useNavigate();
  const { mutate, isPending, isSuccess, error } = useRegisterUser();
  const { errors } = form.formState;
  const { handleSubmit, reset } = form;
  const { executeRecaptcha } = useGoogleReCaptcha();

  const submitRegister = async (formData: RegisterFormTypes) => {
    const { email, name, dateOfBirth } = formData;
    const avatar = pickUserAvatar();
    const recaptchaToken = executeRecaptcha ? await executeRecaptcha("register") : undefined;

    if (isGoogleRegister) {
      externalLogin("google");
      return;
    }
    mutate(
      { email, name, birth: dateOfBirth, avatar, recaptchaToken },
      {
        onSuccess: () => {
          reset();
        },
      }
    );
  };
  const validationError = () => {
    return (
      <div className="h-[44px] text-center text-xs font-medium leading-5 text-red-600 sm:w-[370px]">
        <div>{error?.response?.data.message || get(errors, "dateOfBirth.message")}</div>
      </div>
    );
  };

  if (isSuccess) {
    return <RegisterSuccess />;
  }

  return (
    <>
      <h2 className="mb-8 text-center text-2xl font-bold leading-9 tracking-tight lg:text-[28px]">Age Verification</h2>
      <p className="mb-10 text-xs font-medium leading-[170%] text-primary-black sm:w-[370px]">
        To access AgentX, you must be 18 or the age of majority in your jurisdiction. Users aged 13-18 require parent’s
        approval, while those under 13 are not granted access.
      </p>
      <form onSubmit={handleSubmit(submitRegister)}>
        <div className="flex justify-center ">
          <Controller
            control={form.control}
            name="dateOfBirth"
            render={({ field: { value, onChange } }) => (
              <Datepicker
                selected={value}
                onChange={onChange}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                showTwoColumnMonthYearPicker
                scrollableMonthYearDropdown
                maxDate={subYears(new Date(), 13)}
                className="cursor-pointer rounded-lg border border-neutral-300 text-base text-neutral-700"
                dateFormat="MM/dd/yyyy"
                placeholderText="MM/DD/YYYY"
                calendarClassName="border-none shadow-xl"
              />
            )}
          />
        </div>
        {validationError()}
        <div className="flex flex-col gap-2 pb-12">
          <Button type="submit" loading={isPending} className="w-full text-sm font-bold leading-normal text-white">
            Verify
          </Button>
          <Button
            variant="ghost"
            loading={isPending}
            className="w-full"
            onClick={() => {
              navigate(ROUTES.login);
            }}
          >
            Go back
          </Button>
        </div>
      </form>
    </>
  );
};
