import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogDescription,
  DialogOverlay,
} from "@/components/ui/dialog";
import type { Blocker } from "react-router-dom";
import UnsavedChanges from "@/assets/images/UnsavedChanges.svg";
import { Button } from "@/components/ui/button";

type NavigationBlockerDialogProps = { blocker: Blocker };

export const NavigationBlockerDialog = ({ blocker }: NavigationBlockerDialogProps) => {
  return (
    <Dialog open>
      <DialogOverlay onClick={() => blocker.reset?.()}>
        <DialogContent variant="medium" className="h-[350px]">
          <DialogHeader>Unsaved changes</DialogHeader>
          <DialogDescription asChild className="flex flex-col items-center gap-2.5">
            <div>
              <img src={UnsavedChanges} alt="Folder with files" className="w-[120px]" />
              <p className="text-xl font-bold">You have unsaved changes.</p>
              <p className="text-center text-sm text-neutral-400">Are you sure you want to leave this page?</p>
            </div>
          </DialogDescription>
          <DialogFooter className="flex gap-4">
            <DialogClose asChild>
              <Button size="medium" variant="secondary" onClick={() => blocker.reset?.()}>
                Cancel
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button size="medium" onClick={() => blocker.proceed?.()}>
                Leave
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </DialogOverlay>
    </Dialog>
  );
};
