import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { useCopyNotify } from "@/hooks/useCopyNotify";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { useGetUser } from "@/data/queries/useGetUser";
import { useForm } from "react-hook-form";
import InlineChat from "@/assets/images/embedStyles/Inline.svg";
import FloatingChat from "@/assets/images/embedStyles/Floating.svg";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Button } from "@/components/ui/button";

const chatBubbleUrl = import.meta.env.VITE_CHAT_BUBBLE_URL as string;
type EmbedChatType = "floating" | "inline";

export const EmbedScript = () => {
  const { getValues } = useAgentFormContext();
  const { user } = useGetUser();
  const agentId = getValues("_id");
  const agx = agentId + getValues("websiteEmbeddingKey") ?? user?.agxKey;

  const chatInlineScript = `<div id="chatInlineRoot-${agentId?.slice(18, 24)}">
<script defer src="${chatBubbleUrl}?agx=${encodeURIComponent(agx)}"></script>
</div>`;

  const chatBubbleScript = `<script defer>
document.body.appendChild(document.createElement('div')).setAttribute('id','chatBubbleRoot');
window.agx = '${agx}';
</script>
<script defer src="${chatBubbleUrl}"></script>`;

  const embedChatTypes = {
    floating: chatBubbleScript,
    inline: chatInlineScript,
  };

  const { setValue, watch } = useForm<{ embedStyle: EmbedChatType }>({
    defaultValues: { embedStyle: "floating" },
  });

  const currentChatTypeKey = watch("embedStyle");
  const currentChatTypeEmbed = embedChatTypes[currentChatTypeKey];

  const [, copy] = useCopyNotify();
  return (
    <div className="grid gap-2">
      <Label className="text-md">Generate script</Label>
      <RadioGroup
        onValueChange={(value: EmbedChatType) => setValue("embedStyle", value)}
        defaultValue="floating"
        className="flex items-center gap-4"
      >
        <label className="cursor-pointer text-xs font-medium">
          <img className="mb-2" src={FloatingChat} alt="floating chat widget" />
          <div className="flex items-center justify-center gap-2">
            <RadioGroupItem value="floating" /> Floating
          </div>
        </label>
        <label className="cursor-pointer text-xs font-medium">
          <img className="mb-2" src={InlineChat} alt="floating chat widget" />
          <div className="flex items-center justify-center gap-2">
            <RadioGroupItem value="inline" /> Inline
          </div>
        </label>
      </RadioGroup>
      <p className="mt-3 text-xs font-medium text-neutral-500">
        {currentChatTypeKey === "floating"
          ? "Copy the following script and paste it at the end of your HTML file within the <body> section"
          : "Copy the following script and paste it inside your selected container."}
      </p>
      <Textarea value={currentChatTypeEmbed} className="h-40 resize-none overflow-hidden bg-primary-50 text-xs" />
      <div className="mt-2 flex justify-end">
        <Button onClick={() => copy(currentChatTypeEmbed)}>Copy</Button>
      </div>
    </div>
  );
};
