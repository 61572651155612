import AgentStatusDot from "@/components/AgentStatus/AgentStatusDot";
import { Avatar } from "@/components/Avatar";
import { Icons } from "@/components/ui/icons";
import { ROUTES } from "@/constants/routes";
import type { FullAgent } from "@/types/agent";
import { useNavigate } from "react-router";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { AgentDeploymentsIcons } from "@/components/AgentDeploymentsIcons/AgentDeploymentsIcons";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import { LocalStorageHelpers } from "@/utils/LocalStorageHelpers";
import { createSearchParams } from "react-router-dom";
import { useGetUser } from "@/data/queries/useGetUser";
import { Button } from "@/components/ui/button";
import { IconButton } from "@/components/ui/icon-button";

type WorkspaceAgentTileProps = {
  agent: FullAgent;
  onDeleteAgentClick: () => void;
  //onDuplicateAgentClick: () => void;
  displayOptions: boolean;
};

const WorkspaceAgentTile = ({
  agent,
  onDeleteAgentClick,
  // onDuplicateAgentClick,
  displayOptions,
}: WorkspaceAgentTileProps) => {
  const navigate = useNavigate();
  const { user } = useGetUser();

  const isMyAgent = user?._id === agent.creator;

  const handleGoToStatistics = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    navigate({
      pathname: ROUTES.stats,
      search: createSearchParams({ agent: agent._id }).toString(),
    });
  };

  const onTileClick = () => {
    const lastConversationId = LocalStorageHelpers.getAgentsLocalStorageConversation([agent._id]);

    const newRoute = lastConversationId
      ? ROUTES.conversation(lastConversationId)
      : getNewConversationRoute([agent._id]);

    navigate(newRoute);
  };

  const generateAgentAuthorName = () => (isMyAgent ? "you" : agent.company);

  return (
    <div
      onClick={e => {
        e.stopPropagation();
        onTileClick();
      }}
      className="group flex min-h-52 min-w-60 cursor-pointer flex-col justify-between rounded-md border border-neutral-300 p-4"
    >
      <div className="flex items-center justify-between gap-2">
        <div className="relative">
          <Avatar size="m" src={agent?.avatar} name={agent.name} />
          <AgentStatusDot agentState={agent.state} />
        </div>
        <div className="flex grow items-center justify-between gap-2">
          <div className="flex flex-col gap-1 text-start">
            <h6 className="text-left font-bold leading-5 text-neutral-750 line-clamp-2-wrap">{agent.name}</h6>
            <div className="flex w-[130px] items-center justify-between gap-1">
              {!isMyAgent && <Icons.Team className="size-4 text-primary-400" />}
              <span className="whitespace-nowrap text-xs font-medium text-gray-400">Created by</span>
              <span className="w-[70px] overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium text-gray-400">
                {generateAgentAuthorName()}
              </span>
            </div>
          </div>

          {displayOptions && (
            <DropdownMenu modal={false}>
              <DropdownMenuTrigger onClick={e => e.stopPropagation()} asChild>
                <IconButton variant="tertiary" roundedType="rounded" size="tiny" icon={<Icons.Dots2 />} />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start">
                <DropdownMenuItem
                  className="flex items-center gap-1.5 text-xs"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(ROUTES.editAgent(agent._id));
                  }}
                >
                  <Icons.Edit />
                  Edit
                </DropdownMenuItem>
                {/*  <DropdownMenuItem
                  className="flex items-center gap-1.5 text-xs"
                  onClick={e => {
                    e.stopPropagation();
                    onDuplicateAgentClick();
                  }}
                >
                  <Icons.Copy />
                  Duplicate
                </DropdownMenuItem>*/}
                <div className="my-1 h-[1px] bg-neutral-300" />
                <DropdownMenuItem
                  className="flex items-center gap-1.5 text-xs text-error"
                  onClick={e => {
                    e.stopPropagation();
                    onDeleteAgentClick();
                  }}
                >
                  <Icons.Trash />
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>

      <div className="mt-4 grow rounded-md bg-neutral-50 p-2">
        <p className="line-clamp-3 text-left text-xs font-medium text-neutral-400">
          {agent.inMemoryKnowledge?.about || "This agent does not have bio description"}
        </p>
      </div>

      <div className="mt-4 flex flex-col gap-2">
        <div className="flex items-center justify-between gap-2">
          <AgentDeploymentsIcons deployments={agent.deployments ?? []} />
          <Button
            onClick={handleGoToStatistics}
            variant="tertiary"
            size="tiny"
            className="flex items-center gap-2 bg-neutral-50 p-2 hover:bg-neutral-100"
          >
            <Icons.Stats />
            Stats
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceAgentTile;
