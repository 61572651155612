import { Icons } from "@/components/ui/icons";
import { MAX_FILE_SIZE_INPUT, MAX_FILE_SIZE_MB, isFileFormatAllowed } from "@/utils/fileUploads";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { useUploadFileToConversation } from "@/data/mutations/useUploadFileToConversation";
import type { Agent } from "@/types/agent";
import { domElementIds } from "@/types/dom-element-ids";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { cn } from "@/lib/utils";
import { IconButton } from "@/components/ui/icon-button";

type ChatFileInputProps = {
  agentsIds: Agent["_id"][];
  className?: string;
  disabled?: boolean;
};
const maxNumberOfFiles = 5;

export const ChatFileInput = ({ agentsIds, className, disabled }: ChatFileInputProps) => {
  const {
    conversationId,
    createNewConversation,
    isPreview,
    setFiles,
    files: handledFiles,
    conversationDetails,
  } = useChatContext();
  const { mutate: uploadFile } = useUploadFileToConversation();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const selectedChatKnowledge = conversationDetails?.pins;
  const isFileUploadDisabled =
    (Array.isArray(selectedChatKnowledge) && selectedChatKnowledge.length > 0) ||
    selectedChatKnowledge === null ||
    disabled;

  const handleOpenFileExplorer = () => {
    fileInputRef.current?.click();
  };

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let filesArray = Array.from(e.target.files || []);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    if (!filesArray.length) {
      return;
    }
    if (isFileUploadDisabled) {
      toast.error("You can only upload files with all knowledge sources selected");
      return;
    }

    // check if the number of files exceeds the limit
    // upload only the allowed amount of files
    if (filesArray.length + handledFiles.length > maxNumberOfFiles) {
      toast.error(`You can only send ${maxNumberOfFiles} files at a time`);

      const remainingFiles = maxNumberOfFiles - handledFiles.length;
      if (remainingFiles < 1) {
        return;
      }
      filesArray = filesArray.slice(0, remainingFiles);
    }

    // filter out files that are not allowed
    filesArray = filesArray.filter(file => {
      if (!isFileFormatAllowed(file)) {
        toast.error(`${file.type.split("/")[1].toUpperCase()} file format is not allowed (${file.name})`);
        return false;
      }
      if (file.size > MAX_FILE_SIZE_INPUT) {
        toast.error(`${file.name} exceeds the maximum file size of ${MAX_FILE_SIZE_MB}MB`);
        return false;
      }
      if (handledFiles.some(handledFile => handledFile.originalname === file.name)) {
        toast.error(`${file.name} is already uploaded`);
        return false;
      }
      return true;
    });

    if (!filesArray.length) {
      return;
    }

    if (!conversationId) {
      try {
        if (isPreview) {
          await createNewConversation([agentsIds[0]]);
        } else {
          await createNewConversation(agentsIds);
        }
      } catch (error) {
        console.error(error);
        return;
      }
    }

    // Wait for the conversation to be created
    setTimeout(() => {
      filesArray.forEach(file => {
        uploadFile({ file });
      });
    });
  };

  useEffect(() => {
    return () => setFiles([]);
  }, [setFiles]);

  return (
    <>
      <input
        id={domElementIds.CHAT_FILE_INPUT}
        onChange={handleUploadFile}
        ref={fileInputRef}
        type="file"
        multiple
        accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.heic,.csv"
        hidden
        className="hidden"
        disabled={disabled}
      />
      <IconButton
        variant="tertiary"
        icon={<Icons.PlusCircle className="text-neutral-400" />}
        className={cn("min-w-10", className)}
        disabled={isFileUploadDisabled}
        onClick={handleOpenFileExplorer}
      >
        <span className="sr-only">Add a file</span>
      </IconButton>
    </>
  );
};
