import type React from "react";
import { useState } from "react";
import { Label } from "@/components/ui/label";
import { FormInput } from "@/components/ui/FormInput";
import { FormTextarea } from "@/components/ui/FormTextarea";
import { Icons } from "@/components/ui/icons";
import { useForm } from "react-hook-form";
import { useSendHelpForm } from "@/data/mutations/useSendHelpForm";
import { toast } from "react-toastify";
import { restClient } from "@/data/initAxios";
import { apiPaths } from "@/data/apiPaths";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { MAX_FILE_SIZE_INPUT, MAX_FILE_SIZE_MB, isFileFormatAllowed } from "@/utils/fileUploads";
import { Button } from "@/components/ui/button";
import { IconButton } from "@/components/ui/icon-button";

const FILE_INPUT_ID = "file_input";

export const HelpForm = () => {
  const { mutate, isPending } = useSendHelpForm();
  const form = useForm<{ title: string; content: string; image?: string[] }>({
    defaultValues: {
      title: "",
      content: "",
      image: [],
    },
  });
  const { handleSubmit, setValue, reset } = form;
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const submitHelpForm = (formData: { title: string; content: string }) => {
    mutate(formData, {
      onSuccess: () => {
        reset();
        toast.success("Your request has been sent");
        setImageUrls([]);
      },
    });
  };

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
    if (!isFileFormatAllowed(file)) {
      toast.error("File format not allowed");
      return;
    }
    if (file.size > MAX_FILE_SIZE_INPUT) {
      toast.error(`The file must be less than ${MAX_FILE_SIZE_MB}MB`);
      return;
    }
    setIsFileUploading(true);

    const formData = new FormData();
    formData.append("image", file);
    try {
      const { data } = await restClient.put<{ path: string }>(apiPaths.uploadHelpImage, formData);
      const newImageUrls = [...imageUrls, data.path];
      setImageUrls(newImageUrls);
      setValue("image", newImageUrls);
    } catch (error) {
      toast.error("Something went wrong while uploading the file");
      console.log(error);
    }
    setIsFileUploading(false);
  };

  const openFilePicker = () => {
    const input = document.getElementById(FILE_INPUT_ID);
    if (input) {
      input.click();
    }
  };

  return (
    <form
      onSubmit={handleSubmit(submitHelpForm)}
      className="mx-auto flex h-fit w-full flex-col gap-2 rounded-[20px] bg-white p-6 md:w-[538px]"
    >
      <h1 className="pb-8 text-center text-2xl font-bold">Let us know how we can help you</h1>
      <div className="flex flex-col gap-1.5">
        <div className="flex items-center gap-1">
          <Label htmlFor="title">Title</Label>
          <HybridTooltipPopover>
            <p className="text-sm">Please provide a title for your request</p>
          </HybridTooltipPopover>
        </div>
        <FormInput autoFocus formObj={form} id="title" name="title" placeholder="Tell us what you need" />
      </div>
      <Label htmlFor="content">Message</Label>
      <FormTextarea formObj={form} id="content" name="content" placeholder="Describe your issue" className="h-40" />

      {imageUrls && imageUrls.length > 0 && (
        <>
          <p className="mb-2">Attachments: {imageUrls.length}</p>
          <div className="flex overflow-x-auto">
            {imageUrls?.map((imageUrl, index) => (
              <img
                key={index}
                src={imageUrl}
                alt="uploaded image"
                className="my-2 mr-2 size-32 rounded-md object-cover"
              />
            ))}
          </div>
        </>
      )}
      {isFileUploading && (
        <div className="flex items-center">
          <LoadingSpinner className="scale-75" />
          <p className="ml-2">Uploading file...</p>
        </div>
      )}
      <div className="mt-5 flex items-center justify-between">
        <IconButton
          variant="tertiary"
          onClick={openFilePicker}
          type="button"
          disabled={isPending || isFileUploading}
          icon={<Icons.Paperclip className="text-neutral-400" />}
        />
        <Button type="submit" loading={isPending} disabled={isPending || isFileUploading}>
          Submit
        </Button>
      </div>
      <input
        type="file"
        accept="image/*,.heic"
        hidden
        name="avatar"
        onChange={uploadFile}
        id={FILE_INPUT_ID}
        className="hidden"
      />
    </form>
  );
};
