import { Accordion, AccordionContent, AccordionItem } from "@/components/ui/accordion";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import LeadGenerationContent from "./LeadGenerationContent";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Controller } from "react-hook-form";
import { LeadGenerationWebhook } from "./LeadGenerationWebhook";
import { APP_LINKS } from "@/constants/appLinks";
import { Icons } from "@/components/ui/icons";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { LinkButton } from "@/components/ui/link-button";

const LeadGeneration = () => {
  const { watch, control, clearErrors, setValue } = useAgentFormContext();

  const isLeadGenEnabled = watch("leadGeneration.enabled");

  return (
    <Accordion
      type="single"
      collapsible
      className="relative w-full border-b border-neutral-100 py-4"
      value={isLeadGenEnabled ? "enabled" : ""}
    >
      <AccordionItem value="enabled">
        <div className="flex justify-between">
          <div className="flex items-center gap-1">
            <h4>Lead Generation</h4>
            <HybridTooltipPopover heading="Lead Generation Tool">
              <p>
                Use this tool to boost your inbound lead generation. It allows the agent to collect information from
                your customers. You can also connect it to an external service such as CRM or email marketing tool.
              </p>
            </HybridTooltipPopover>
          </div>

          <div className="flex items-center gap-4">
            <Controller
              control={control}
              name="leadGeneration.enabled"
              render={({ field: { value, onChange } }) => (
                <>
                  <Label htmlFor="parameterRequired">Enabled</Label>
                  <Switch
                    id="parameterRequired"
                    checked={value}
                    onCheckedChange={checked => {
                      if (!checked) {
                        setValue("leadGeneration.webhook.enabled", false);
                        clearErrors("leadGeneration.webhook.url");
                      }

                      onChange(checked);
                    }}
                  />
                </>
              )}
            />
          </div>
        </div>

        <div className="mt-1 flex items-center gap-2 ">
          {isLeadGenEnabled && <Icons.Check className="text-success" />}
          <p className={`text-xs font-medium ${isLeadGenEnabled ? "text-neutral-650" : "text-neutral-400"}`}>
            By enabling Lead Generation, you agree to our{" "}
            <LinkButton type="button" className="text-primary-500">
              <a href={APP_LINKS.privacy} target="_blank" rel="noopener noreferrer">
                Privacy Notice
              </a>
            </LinkButton>{" "}
            and{" "}
            <LinkButton type="button" className="p-0 text-primary-500">
              <a href={APP_LINKS.termsOfService} target="_blank" rel="noopener noreferrer">
                Terms of Service
              </a>
            </LinkButton>
            .
          </p>
        </div>

        <AccordionContent className="flex flex-1 flex-col pl-1">
          <LeadGenerationContent />
          <LeadGenerationWebhook />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default LeadGeneration;
