import { Button } from "@/components/ui/button";
import { useAccountUsageDialogContext } from "@/contexts/AccountUsageDialogContext/useAccountUsageDialogContext";

export const StatsUpgradeMessage = ({ suffixText }: { suffixText: string }) => {
  const { openDialog } = useAccountUsageDialogContext();

  const onUpgradeClick = () => openDialog("stats");

  return (
    <p className="absolute left-1/2 top-1/2 mt-4 -translate-x-1/2 -translate-y-1/2 text-center font-bold">
      <Button onClick={onUpgradeClick} variant="ghost" size="custom" className="mr-[3px] p-0 font-bold text-purple-400">
        Upgrade
      </Button>{" "}
      {suffixText}
    </p>
  );
};
