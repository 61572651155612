import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import { useForm } from "react-hook-form";
import { AuthorizedDomains } from "../../AuthorizedDomains";
import { BotColour } from "./BotColour";
import { EmbedScript } from "./EmbedScript";
import { PreviewChatDialog } from "./PreviewChatDialog";
import { CustomBranding } from "./CustomBranding";
import { useAgentFormContext } from "../../../../../hooks/useAgentFormContext";
import { AgentFormPublishSchema } from "@/components/AgentForm/AgentFormValidationSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import type { z } from "zod";
import { BubbleDisplay } from "./BubbleDisplay";
import { StyleSet } from "./StyleSet";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import { Button } from "@/components/ui/button";

type WebsiteEmbeddingFormValues = z.infer<typeof AgentFormPublishSchema>;

type WebsiteEmbeddingDialogProps = {
  isDialogOpen: boolean;
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const WebsiteEmbeddingDialog = ({ isDialogOpen, setIsDialogOpen }: WebsiteEmbeddingDialogProps) => {
  const agentForm = useAgentFormContext();
  const publishInitValues = agentForm.getValues("publish");
  const [isPreviewChatOpen, setIsPreviewChatOpen] = useState(false);

  const websiteEmbeddingForm = useForm<WebsiteEmbeddingFormValues>({
    defaultValues: publishInitValues,
    resolver: zodResolver(AgentFormPublishSchema),
  });

  const handleFormReset = () => {
    setTimeout(() => {
      websiteEmbeddingForm.reset(publishInitValues);
    }, 300);
  };

  const handleSaveForm = (values: WebsiteEmbeddingFormValues) => {
    agentForm.setValue(
      "publish",
      {
        ...values,
        isConfigured: true,
        isPublished: values.isConfigured ? values.isPublished : true,
      },
      { shouldDirty: true }
    );

    setIsDialogOpen(false);
  };

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onOpenChange={isOpen => {
          setIsDialogOpen(isOpen);
          handleFormReset();
        }}
      >
        <DialogContent
          className="lg:h-[800px]"
          onInteractOutside={e => {
            if (isPreviewChatOpen) {
              e.preventDefault();
            }
          }}
        >
          <DialogHeader>Website embedding setting</DialogHeader>
          <div className="flex flex-wrap overflow-auto px-8 py-4 ">
            <div className="w-full pb-10 lg:w-2/3 lg:pb-0">
              <Label className="text-md">Customise Chat Bubble</Label>
              <div className="flex flex-col gap-5 overflow-y-auto pt-2">
                <BubbleDisplay form={websiteEmbeddingForm} />
                <StyleSet form={websiteEmbeddingForm} />
                <BotColour form={websiteEmbeddingForm} />
                <CustomBranding form={websiteEmbeddingForm} />
                <Button className="ml-auto mr-auto" variant="secondary" onClick={() => setIsPreviewChatOpen(true)}>
                  Preview Your Agent
                </Button>
              </div>
            </div>
            <div className="w-full lg:w-1/3 lg:pl-5">
              <AuthorizedDomains form={websiteEmbeddingForm} />
              <EmbedScript />
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="tertiary">Cancel</Button>
            </DialogClose>
            <DialogClose asChild>
              <Button onClick={websiteEmbeddingForm.handleSubmit(handleSaveForm)}>Save</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <PreviewChatDialog isOpen={isPreviewChatOpen} onClose={() => setIsPreviewChatOpen(false)} />
    </>
  );
};
