import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import type { F0 } from "@/types/types";
import { useState } from "react";

type PublishNewAgentProps = {
  onClick: F0;
  text: string;
  className?: string;
  disabled?: boolean;
};

export const PublishNewAgent = ({ onClick, text, className, disabled }: PublishNewAgentProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      onMouseOut={() => setIsHovered(false)}
      onMouseOver={() => setIsHovered(true)}
      className={cn(
        "flex min-h-44 w-full flex-col items-center justify-center gap-3 rounded-lg border border-neutral-200 bg-neutral-50 4xl:w-auto 4xl:max-w-[260px] 4xl:basis-full",
        className,
        {
          "cursor-not-allowed": disabled,
        }
      )}
    >
      <div
        className={cn(
          "flex size-10 items-center justify-center rounded-md border border-neutral-400 bg-neutral-50",
          isHovered && "bg-neutral-200"
        )}
      >
        <Icons.Plus className="p-0.5" />
      </div>
      <div className="text-sm font-medium">{text}</div>
    </button>
  );
};
