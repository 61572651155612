import { Icons } from "@/components/ui/icons";
import { useCopyNotify } from "@/hooks/useCopyNotify";
import { shareToDiscord, shareToFacebook, shareToLinkedIn, shareToTwitter } from "@/utils/shareLink";
import type { HTMLAttributes } from "react";
import { Button } from "./ui/button";

type SocialMediaShareProps = HTMLAttributes<HTMLDivElement> & {
  shareLink: string;
};

export const SocialMediaShare = ({ shareLink, ...props }: SocialMediaShareProps) => {
  const [, copy] = useCopyNotify();

  return (
    <div {...props}>
      <div className="mb-2 w-full text-center text-base font-bold text-primary-500">Share</div>

      <div className="flex items-center justify-center gap-7">
        <Button
          className="rounded-full border border-neutral-300 p-2.5 transition-all hover:scale-105"
          variant="ghost"
          onClick={() => copy(shareLink)}
        >
          <Icons.Link2 className="size-5 text-primary-black" />
        </Button>

        <Button
          className="rounded-full bg-[#3B5998] p-2.5 transition-all hover:scale-105"
          variant="ghost"
          onClick={() => {
            copy(shareLink);
            shareToDiscord();
          }}
        >
          <Icons.Discord className="size-6 text-white" />
        </Button>

        {/*} <Button className="size-10 rounded-full bg-white" variant="ghost" onClick={() => shareToGithub(shareLink)}>
          <Icons.Github className="scale-[4.9] text-primary-black" />
        </Button>*/}

        <Button
          className="rounded-full bg-[#007AB9] p-2.5 transition-all hover:scale-105"
          variant="ghost"
          onClick={() => shareToLinkedIn(shareLink)}
        >
          <Icons.LinkedIn className="mb-0.5 size-5 text-white" />
        </Button>

        <Button
          className="rounded-full bg-black p-2.5 transition-all hover:scale-105"
          variant="ghost"
          onClick={() => shareToTwitter(shareLink)}
        >
          <Icons.TwitterX className="size-4 text-white" />
        </Button>

        <Button
          className="rounded-full bg-[#3B5998] p-2.5 transition-all hover:scale-105"
          variant="ghost"
          onClick={() => shareToFacebook(shareLink)}
        >
          <Icons.Facebook className="size-5 text-white" />
        </Button>
      </div>
    </div>
  );
};
