import UserLicenceSelector from "@/components/dialogs/AccountUsageDialog/components/UserLicenceSelector";
import { CancelSubscriptionDialog } from "@/components/dialogs/BillingDialogs/CancelSubscriptionDialog";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import { useGetUser } from "@/data/queries/useGetUser";
import { cn } from "@/lib/utils";
import { SubscriptionTierNames, SubscriptionTiers, type BillingData } from "@/types/subscriptions";

export const BillingPlan = ({ billingInfo }: { billingInfo: BillingData | undefined }) => {
  const { user } = useGetUser();
  const { openDialog: openSubscriptionTierDialog } = useSubscriptionTierDialogContext();
  return (
    <div>
      <div className="mb-4 mt-6 flex justify-between text-lg font-bold">
        <div>
          Your{" "}
          <span className="capitalize text-primary-400">{SubscriptionTierNames[user?.subscription.tier ?? 0]}</span>{" "}
          Plan
        </div>
        <span className="flex items-center gap-1 rounded-full bg-success/50 px-4 py-1 text-xs font-medium leading-5 text-white">
          <Icons.CheckCircle className="size-4" />
          ACTIVE
        </span>
      </div>
      <div className="flex flex-col justify-between gap-x-10 gap-y-5 sm:flex-row sm:items-end">
        <div
          className={cn(
            "grid w-full grid-cols-2 justify-stretch gap-x-5 rounded-md border border-neutral-300 p-4 text-xs font-medium leading-5 text-neutral-600 xs:max-w-[280px]",
            !billingInfo && "opacity-50"
          )}
        >
          <span>Price</span>
          <span className="text-right">
            ${billingInfo ? `${(billingInfo?.subscription.price / 100).toFixed(2)}` : "--/m"}
          </span>
          <span>Billing Period</span>
          <span className="text-right">
            {billingInfo ? (billingInfo.subscription.interval === "month" ? "monthly" : "annually") : "-"}
          </span>
          <span>Renewal date</span>
          <span className="text-right">
            {billingInfo
              ? new Date(billingInfo.subscription.nextBillingDate * 1000).toLocaleDateString()
              : "--/--/----"}
          </span>
        </div>

        <div className="mt-2 flex flex-wrap gap-2 sm:justify-between">
          {billingInfo ? (
            <>
              <CancelSubscriptionDialog />
              {user?.subscription.tier !== SubscriptionTiers.ENTERPRISE && (
                <Button
                  disabled={false}
                  variant="tertiary"
                  onClick={() => openSubscriptionTierDialog()}
                  prefixIcon={<Icons.Exchange />}
                >
                  Change Plan
                </Button>
              )}
            </>
          ) : (
            <Button variant="secondary" onClick={() => openSubscriptionTierDialog()}>
              Choose a plan
            </Button>
          )}
        </div>
      </div>

      <UserLicenceSelector />
    </div>
  );
};
