import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import type { Agent } from "@/types/agent";
import { useDeleteAgent } from "@/data/mutations/useDeleteAgent";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import DeleteBin from "@/assets/images/DeleteBin.svg";
import type { F1 } from "@/types/types";
import { Button } from "@/components/ui/button";

type DeleteAgentDialogProps = {
  agentId: Agent["_id"] | null;
  setAgentId: F1<Agent["_id"] | null>;
  isDialogOpen: boolean;
  setShowDialog: F1<boolean>;
};

export const DeleteAgentDialog = ({ agentId, setAgentId, isDialogOpen, setShowDialog }: DeleteAgentDialogProps) => {
  const { mutateAsync, isPending } = useDeleteAgent();
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();

  const deleteAgent = async () => {
    if (!agentId) {
      return;
    }
    await mutateAsync(agentId);
    if (agentId === id) {
      navigate(ROUTES.workspace);
    }
    setShowDialog(false);
    setAgentId(null);
  };

  const toggleDialog = (open: boolean) => {
    setShowDialog(open);
    setAgentId(null);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={toggleDialog}>
      <DialogContent variant="medium" className="h-[350px]">
        <DialogHeader></DialogHeader>
        <div className="flex flex-col items-center gap-2.5 px-8 py-4">
          <img src={DeleteBin} alt="Delete Bin" className="w-20" />
          <div className="text-xl font-bold">Are you sure you want to Delete this agent?</div>
          <div className="text-center text-sm text-neutral-400">
            Do you really want to delete this Agent? Please note this action cannot be undone.
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button variant="danger" onClick={deleteAgent} loading={isPending}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
