import * as XLSX from "xlsx";
import { parse } from "papaparse";
import { MAX_FILE_SIZE_INPUT, MAX_FILE_SIZE_MB } from "./fileUploads";

export const getKnowledgeFromFile = async (file: File): Promise<{ column1: string; column2: string }[]> => {
  return new Promise((resolve, reject) => {
    const allowedExtensions = [".csv", ".xls", ".xlsx"];
    const ext = file.name.slice(file.name.lastIndexOf(".")).toLowerCase();

    if (!allowedExtensions.includes(ext)) {
      reject(new Error("Unsupported file format. Only CSV, XLS, and XLSX are allowed."));
      return;
    }

    if (file.size > MAX_FILE_SIZE_INPUT) {
      reject(new Error(`The file ${file.name} must be less than ${MAX_FILE_SIZE_MB}MB`));
      return;
    }

    const invalidStructureMessage = "Invalid file structure. Ensure there are at least two columns.";

    try {
      const processRow = (row: unknown[]): { column1: string; column2: string } | null => {
        if (row.length < 2) {
          return null;
        }

        const column1 = row[0]?.toString().trim();
        const column2 = row[1]?.toString().trim();

        if (!column1 || !column2) {
          return null;
        }

        return { column1, column2 };
      };

      if (ext === ".csv") {
        parse(file, {
          header: false,
          skipEmptyLines: true,
          complete: results => {
            const rows = results.data
              .map(row => processRow(row as unknown[]))
              .filter((row): row is { column1: string; column2: string } => row !== null);

            if (rows.length === 0) {
              reject(new Error(invalidStructureMessage));
              return;
            }

            resolve(rows);
          },
          error: error => {
            reject(new Error(`Error processing file: ${error.message}`));
          },
        });
      } else if (ext === ".xls" || ext === ".xlsx") {
        const reader = new FileReader();
        reader.onload = e => {
          try {
            const workbook = XLSX.read(e.target?.result, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const json: unknown[][] = XLSX.utils.sheet_to_json(sheet, {
              header: 1,
            });

            const rows = json
              .map(row => processRow(row))
              .filter((row): row is { column1: string; column2: string } => row !== null);

            if (rows.length === 0) {
              reject(new Error(invalidStructureMessage));
              return;
            }

            resolve(rows);
          } catch (err) {
            reject(new Error(`Error processing Excel file: ${(err as Error).message}`));
          }
        };

        reader.onerror = () => {
          reject(new Error("Error reading file"));
        };

        reader.readAsArrayBuffer(file);
      }
    } catch (err) {
      reject(new Error(`Error processing file: ${(err as Error).message}`));
    }
  });
};
