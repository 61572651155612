import { Label } from "@/components/ui/label";
import { useState } from "react";
import { ReactMultiEmail } from "react-multi-email";
import { toast } from "react-toastify";
import { cn } from "@/lib/utils";
import "react-multi-email/dist/style.css";
import { Button } from "./ui/button";

export type OnShareMutationCallback = ({
  emails,
  onSuccessCallback,
}: {
  emails: string[];
  onSuccessCallback: () => void;
}) => void;

type ShareMultiEmailsProps = {
  onShare: OnShareMutationCallback;
  label?: string;
  isMutationPending: boolean;
  className?: string;
};

export const ShareMultiEmails = ({
  label = "Send through emails",
  onShare,
  isMutationPending,
  className,
}: ShareMultiEmailsProps) => {
  const [emails, setEmails] = useState<string[]>([]);

  const inviteByEmail = () => {
    if (!emails.length) {
      return;
    }
    const onSuccessCallback = () => {
      setEmails([]);
      toast.success("Invitation sent!");
    };

    onShare({ emails, onSuccessCallback });
  };

  return (
    <div className={cn("flex w-full flex-col gap-1.5", className)}>
      <Label htmlFor="multiEmail">{label}</Label>
      <div className="flex flex-col gap-4 pl-1">
        <ReactMultiEmail
          id="multiEmail"
          className="!rounded-md border-neutral-300 bg-white text-[14px] text-neutral-700 focus-within:!border-primary-300 focus-within:ring-1 focus-within:ring-primary-600 focus-within:ring-offset-2"
          inputClassName="focus-visible:ring-0"
          placeholder="Type an email and press enter..."
          emails={emails}
          onChange={(_emails: string[]) => setEmails(_emails)}
          getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
            return (
              <div data-tag key={index}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            );
          }}
        />

        <div className="flex justify-end gap-x-4">
          <Button variant="tertiary" onClick={() => setEmails([])}>
            Cancel
          </Button>
          <Button onClick={inviteByEmail} loading={isMutationPending} disabled={!emails.length}>
            Send invite
          </Button>
        </div>
      </div>
    </div>
  );
};
