import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";

type SubscriptionTierPricingCardProps = {
  title: string;
  subtitle?: string;
  description: string;
  currentPrice: string;
  crossedOutPrice?: string;
  periodUnit?: string;
  btn: {
    text: string;
    variant: "primary" | "tertiary";
    onClick?: () => void;
    disabled?: boolean;
  };
  isHighlighted?: boolean;
  className?: string;
};

export const SubscriptionTierPricingCard = ({
  title,
  subtitle,
  description,
  currentPrice,
  crossedOutPrice,
  periodUnit,
  btn,
  isHighlighted = false,
  className,
}: SubscriptionTierPricingCardProps) => {
  return (
    <Card
      className={cn(
        "flex min-h-96 w-full max-w-60 flex-col justify-between rounded-md border-t-8 border-transparent px-4 pb-4 pt-6 shadow-xl hover:bg-primary-100",
        {
          "!border-primary-400": isHighlighted,
        },
        className
      )}
    >
      <div className="space-y-4">
        <div className="flex w-fit items-center gap-4">
          <h3 className="bg-membership bg-clip-text text-2xl text-transparent">{title}</h3>
          <span className="ml-4 text-xs font-medium leading-5 text-violet-600">{subtitle}</span>
        </div>

        <div className="flex items-center justify-start gap-4">
          {!!crossedOutPrice && <h4 className="text-2xl font-medium text-gray-400 line-through">{crossedOutPrice}</h4>}
          <h4 className="text-2xl">
            {currentPrice}
            {!!periodUnit && <span className="text-xs font-medium leading-5 text-neutral-400">/{periodUnit}</span>}
          </h4>
        </div>

        <div className="flex flex-grow items-end text-xs font-medium leading-5 text-neutral-600">{description}</div>
      </div>

      <Button onClick={btn.onClick} variant={btn.variant} className="mt-4" disabled={btn.disabled}>
        {btn.text}
      </Button>
    </Card>
  );
};
