import { Icons } from "@/components/ui/icons";
import { Label } from "@/components/ui/label";
import type { EmbedKnowledges } from "@/types/agent";
import { sortWebByDomainsToSeparateArrays } from "@/utils/getDomains";
import { useEffect, useState } from "react";
import { AgentDetailsEmbedKnowledgeDialog } from "./AgentDetailsEmbedKnowledgeDialog";
import { cn } from "@/lib/utils";
import { AgentDetailsEmbedKnowledgeList } from "./AgentDetailsEmbedKnowledgeList";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { useSetConversationQueryKnowledge } from "@/components/Chat/hooks/useSetConversationQueryKnowledge";
import { IconButton } from "@/components/ui/icon-button";
import { Button } from "@/components/ui/button";

interface AgentDetailsEmbedKnowledgeProps {
  embedKnowledges: {
    web: EmbedKnowledges[];
    doc: EmbedKnowledges[];
    faq: EmbedKnowledges[];
  };
}

const AgentDetailsEmbedKnowledge = ({ embedKnowledges }: AgentDetailsEmbedKnowledgeProps) => {
  const { files, conversationDetails } = useChatContext();
  const { setKnowledge } = useSetConversationQueryKnowledge();
  const isNewConversation = !conversationDetails;
  const selectedChatKnowledge = isNewConversation ? [] : conversationDetails.pins;
  const mergedKnowledges = [...embedKnowledges.doc, ...embedKnowledges.web, ...embedKnowledges.faq];
  const knowledgesCount = mergedKnowledges.length;
  const sortedWebData = embedKnowledges.web.length ? sortWebByDomainsToSeparateArrays(embedKnowledges.web) : [];

  const [showKnowledgeTable, setShowKnowledgeTable] = useState(false);

  useEffect(() => {
    if (files.length) {
      setShowKnowledgeTable(false);
    }
  }, [files]);

  const handleSimplePinChange = (checked: boolean, idOrIds: string | string[]) => {
    setKnowledge(knowledgeArray => {
      const ids = Array.isArray(idOrIds) ? idOrIds : [idOrIds];
      if (checked) {
        ids.forEach(id => {
          if (!knowledgeArray.includes(id)) {
            knowledgeArray.push(id);
          }
        });
      } else {
        knowledgeArray = knowledgeArray.filter(id => !ids.includes(id));
      }

      return knowledgeArray.length === mergedKnowledges.length ? [] : knowledgeArray;
    });
  };

  const handleClearPins = () => {
    setKnowledge([]);
  };

  return (
    <div className="w-full">
      <Label className="font-bold">Knowledge</Label>

      <div className="my-4 flex items-stretch gap-2 [&>div]:flex-1">
        <div className="rounded-md bg-neutral-50 px-2 py-1">
          <p className="text-center text-sm font-bold leading-6 text-neutral-750">{embedKnowledges.doc.length}</p>
          <div className="flex items-center justify-center gap-2">
            <Icons.File className="size-4 min-w-4 text-neutral-400" />
            <p className="text-xs font-medium text-neutral-400">Document</p>
          </div>
        </div>

        <div className="col-span-3 rounded-md bg-neutral-50 px-2 py-1">
          <p className="text-center text-sm font-bold leading-6 text-neutral-750">{embedKnowledges.web.length}</p>
          <div className="flex items-center justify-center gap-2">
            <Icons.Link2 className="size-4 min-w-4 text-neutral-400" />
            <p className="text-xs font-medium text-neutral-400">Weblink</p>
          </div>
        </div>

        <div className="col-span-3 rounded-md bg-neutral-50 px-2 py-1">
          <p className="text-center text-sm font-bold leading-6 text-neutral-750">{embedKnowledges.faq.length}</p>
          <div className="flex items-center justify-center gap-2">
            <Icons.Message className="size-4 min-w-4 text-neutral-400" />
            <p className="text-xs font-medium text-neutral-400">FAQ</p>
          </div>
        </div>
        <IconButton
          type="button"
          variant="secondary"
          variantColor="grey"
          size="small"
          disabled={!!files.length}
          icon={
            <Icons.ChevronRight className={cn("rotate-90 transition-transform", showKnowledgeTable && "-rotate-90")} />
          }
          onClick={() => setShowKnowledgeTable(prev => !prev)}
        />
      </div>
      {showKnowledgeTable && (
        <div className={cn("min-h-40 bg-neutral-50", isNewConversation && "pointer-events-none relative")}>
          {isNewConversation && (
            <div className="absolute left-0 top-0 z-10 grid size-full place-items-center backdrop-blur-[5px]">
              <p className="px-5 text-center text-base font-bold">
                Please <span className="text-primary-400">send a message</span> first &<br /> then select ‘Knowledge’
              </p>
            </div>
          )}
          <div className="flex items-center justify-between border-b border-neutral-200 px-4 py-2">
            <Button
              disabled={!selectedChatKnowledge.length}
              variant="tertiary"
              size="tiny"
              className="pl-0"
              onClick={handleClearPins}
            >
              Clear
            </Button>

            <span className="text-xxs font-medium text-primary-400">
              {selectedChatKnowledge.length === 0
                ? "all knowledge selected"
                : `selected knowledge (${selectedChatKnowledge.length}/${knowledgesCount})`}
            </span>
            <AgentDetailsEmbedKnowledgeDialog
              selectedChatKnowledge={selectedChatKnowledge}
              embedKnowledges={embedKnowledges}
              knowledgesCount={knowledgesCount}
              handleSimplePinChange={handleSimplePinChange}
              handleClearPins={handleClearPins}
              sortedWebData={sortedWebData}
            />
          </div>
          <div className="max-h-64 overflow-y-auto text-xxs font-medium">
            <AgentDetailsEmbedKnowledgeList
              selectedChatKnowledge={selectedChatKnowledge}
              sortedWebData={sortedWebData}
              embedKnowledges={embedKnowledges}
              handleSimplePinChange={handleSimplePinChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AgentDetailsEmbedKnowledge;
