import { SubscriptionPeriod, SubscriptionTiers } from "@/types/subscriptions";
import { AccountUsageBenefitList } from "./AccountUsageBenefitList";
import { AccountUsagePricingCard } from "./AccountUsagePricingCard";
import { toast } from "react-toastify";
import { useGetBillingPlans } from "@/data/queries/subscriptions/useGetBillingPlans";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import { useGetUser } from "@/data/queries/useGetUser";
import { LoadingSpinner } from "@/components/ui/loading-spinner";

type AccountUsageProCardProps = {
  period?: SubscriptionPeriod;
  showButton?: boolean;
  highlightText?: string;
  isHighlighted?: boolean;
};
export const AccountUsageProCard = ({
  period = SubscriptionPeriod.ANNUALLY,
  showButton = true,
  highlightText = "MOST POPULAR",
  isHighlighted = true,
}: AccountUsageProCardProps) => {
  const { user } = useGetUser();
  const { openDialog } = useSubscriptionTierDialogContext();
  const { data: plans, isLoading } = useGetBillingPlans();

  if (isLoading) {
    return <LoadingSpinner className="flex size-full items-center justify-center" />;
  }

  if (!plans) {
    toast.error("Failed to retrieve billing plans information");
    return null;
  }
  const proPlan = plans.billingPlans[SubscriptionTiers.PRO];
  const monthPriceOnAnnual =
    period === SubscriptionPeriod.ANNUALLY
      ? (proPlan.price[SubscriptionPeriod.ANNUALLY].amount / 12).toString()
      : proPlan.price[SubscriptionPeriod.MONTHLY].amount.toString();
  const features = proPlan?.features_as_text.split(", ");

  const onSelectProPlan = () => {
    openDialog({
      selectedPlan: { plan: proPlan, period },
      // todo: make one component out of two below to avoid calling user query
      step: user?.subscription.stripeId ? "changeSubscriptionTier" : "stripePaymentSummary",
    });
  };

  return (
    <AccountUsagePricingCard
      title="PRO"
      price={monthPriceOnAnnual}
      periodUnit={period === SubscriptionPeriod.ANNUALLY ? "year" : "month"}
      highlightText={highlightText}
      isHighlighted={isHighlighted}
      {...(showButton && { btn: { text: "Subscribe", variant: "primary", onClick: onSelectProPlan } })}
    >
      <AccountUsageBenefitList features={features} />
    </AccountUsagePricingCard>
  );
};
