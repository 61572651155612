import type { Agent } from "@/types/agent";
import { TableCell, TableRow } from "@/components/ui/table";
import { Avatar } from "@/components/Avatar";
import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { Icons } from "@/components/ui/icons";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import AgentStatusDot from "@/components/AgentStatus/AgentStatusDot";
import AgentStatusLabel from "@/components/AgentStatus/AgentStatusLabel";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useNavigate } from "react-router";
import { ROUTES } from "@/constants/routes";
import type { StatOrigin } from "@/types/origin";
import { channelsLegendData, getAgentOverviewRowId } from "../../constants/statsConstants";
import { StatsSingleAgentRowDetails } from "../StatsSingleAgentRowDetails/StatsSingleAgentRowDetails";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import StatsDownvotedMessagesDialog from "../StatsTopAgents/StatsDownvotedMessagesDialog";
import { IconButton } from "@/components/ui/icon-button";
import { useGetAgentConversationHistoryReport } from "@/data/queries/stats/useGetAgentConversationHistoryReport";
import { LoadingSpinner } from "@/components/ui/loading-spinner";

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
  agent: Agent;
  messages: number;
  conversations: number;
  satisfaction: number | null;
  channels: {
    channel: StatOrigin;
    value: number;
  }[];
  currentColumnIndexes: number[];
  onRowDetailsOpen: ({ scrollToElementId }: { scrollToElementId: string }) => void;
};

const StatsAgentsOverviewSingleAgentRow = ({
  agent,
  messages,
  conversations,
  satisfaction,
  channels,
  currentColumnIndexes,
  onRowDetailsOpen,
}: Props) => {
  const navigate = useNavigate();

  const getIsColumnVisible = (index: number) => currentColumnIndexes.includes(index);

  const { selectedAgentId, setSelectedAgentId, setChatHistoryDialogProps, currentRange, startDate, endDate } =
    useStatsContext();

  const [isOpen, setIsOpen] = useState(false);
  const [isDownvotedMessagesDialogOpen, setIsDownvotedMessagesDialogOpen] = useState(false);

  const isAgentSelected = agent._id === selectedAgentId;

  const allowedChannels = channelsLegendData.map(item => item.channel);

  const channelsFiltered = channels.filter(channel => allowedChannels.includes(channel.channel));

  const { downloadReport, isLoadingDownloadReport } = useGetAgentConversationHistoryReport();

  const handleDownloadReport = () => {
    void downloadReport({
      agentId: agent._id,
      agentName: agent.name,
      currentRange,
      startDate,
      endDate,
      minMessages: null,
      maxMessages: null,
      searchValue: "",
      onlyWithDownvotes: false,
    });
  };

  const chartData = {
    datasets: [
      {
        data: channelsFiltered.map(channel => channel.value),
        backgroundColor: channelsFiltered.map(channel => {
          return channelsLegendData.find(item => item.channel === channel.channel)?.color;
        }),
      },
    ],
  };

  const handleToggleChartSelected = () => {
    const newAgentId = isAgentSelected ? null : agent._id;

    setSelectedAgentId(newAgentId);
  };

  const areChannelsEmpty = channelsFiltered.every(channel => channel.value === 0) || channelsFiltered.length === 0;

  const tableCells = [
    {
      id: "1",
      className: "",
      content: messages,
    },
    {
      id: "2",
      className: "",
      content: conversations,
    },
    {
      id: "3",
      className: "whitespace-nowrap",
      content: satisfaction !== undefined && satisfaction !== null && `${satisfaction}%`,
    },
    {
      id: "4",
      className: "relative",
      content: !areChannelsEmpty && (
        <CollapsibleTrigger>
          <Doughnut
            data={chartData}
            height={45}
            width={45}
            options={{
              maintainAspectRatio: true,
              aspectRatio: 1,
              responsive: false,
              cutout: "68%",
              layout: {
                padding: 0,
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
              elements: {
                arc: {
                  borderWidth: 0,
                },
              },
            }}
            className={cn("opacity-70 hover:opacity-100", {
              "opacity-100": isOpen,
            })}
          />
        </CollapsibleTrigger>
      ),
    },
    {
      id: "5",
      className: "",
      content: (
        <div className="flex items-center justify-center @3xl/main:justify-start">
          <div className="flex w-fit items-center gap-2 rounded-lg px-3 py-1 shadow-md">
            <AgentStatusDot agentState={agent.state} className="static" />
            <AgentStatusLabel agentState={agent.state} className="text-xs" />
          </div>
        </div>
      ),
    },
    {
      id: "6",
      className: "",
      content: (
        <button onClick={handleToggleChartSelected}>
          <Icons.BarChart
            className={cn("cursor-pointer text-neutral-400 hover:text-primary-300", {
              "text-primary-400": isAgentSelected,
            })}
          />
        </button>
      ),
    },
  ];

  useEffect(() => {
    if (isOpen) {
      onRowDetailsOpen({ scrollToElementId: getAgentOverviewRowId(agent._id) });
    }
  }, [agent._id, isOpen, onRowDetailsOpen]);

  return (
    <>
      <Collapsible key={agent._id} asChild open={isOpen} onOpenChange={open => setIsOpen(open)}>
        <>
          <TableRow
            key={agent._id}
            id={getAgentOverviewRowId(agent._id)}
            className={cn("relative ", {
              "border-b border-b-border-light": !isOpen,
            })}
          >
            <TableCell className="relative max-w-[7.2rem] @xl/main:max-w-full @3xl/main:max-w-full">
              <div className="flex items-center gap-2 pl-2">
                <Avatar src={agent.avatar} size="s" />
                <p className="truncate text-sm">{agent.name}</p>
              </div>
              <div
                className={cn("absolute left-0 top-0 h-full w-2", {
                  "bg-primary-400": isOpen,
                })}
              />
              <div
                className={cn(
                  "absolute right-0 top-0 h-full w-full shadow-[2px_0px_4px_0px_rgba(0,0,0,0.15)] @3xl/main:shadow-none"
                )}
              />
            </TableCell>

            {tableCells.map((cell, index) => (
              <TableCell
                key={cell.id}
                className={cn(
                  cell.className,
                  "hidden w-[1000px] text-center @3xl/main:table-cell @3xl/main:text-left",
                  {
                    "table-cell": getIsColumnVisible(index),
                  }
                )}
              >
                {cell.content}
              </TableCell>
            ))}

            <TableCell className="flex items-center text-right shadow-[-2px_0px_4px_0px_rgba(0,0,0,0.15)] @3xl/main:shadow-none">
              <DropdownMenu>
                <DropdownMenuTrigger className="rotate-90" onClick={e => e.stopPropagation()} asChild>
                  <IconButton variant="tertiary" size="medium" roundedType="rounded" icon={<Icons.Dots2 />} />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                  <DropdownMenuItem
                    className="flex items-center gap-1.5 text-xs"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate(ROUTES.editAgent(agent._id));
                    }}
                  >
                    <Icons.Edit className="size-4" />
                    Edit agent
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    onSelect={() => {
                      setChatHistoryDialogProps({
                        agent,
                      });
                    }}
                  >
                    <button className="flex items-center gap-1.5 text-xs">
                      <Icons.History className="size-4 text-black" />
                      Chat history
                    </button>
                  </DropdownMenuItem>

                  <DropdownMenuItem onSelect={handleDownloadReport}>
                    <button className="flex items-center gap-1.5 text-xs" disabled={isLoadingDownloadReport}>
                      {isLoadingDownloadReport ? (
                        <LoadingSpinner iconClassName="size-4" />
                      ) : (
                        <Icons.Download className="size-4 text-black" />
                      )}
                      Download chat history
                    </button>
                  </DropdownMenuItem>

                  <DropdownMenuItem>
                    <button
                      onClick={() => setIsDownvotedMessagesDialogOpen(true)}
                      className="flex items-center gap-1.5 text-xs"
                    >
                      <Icons.ThumbDown className="size-4 text-black" />
                      Downvoted messages
                    </button>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>

              <CollapsibleTrigger className="p-3">
                <IconButton
                  variant="tertiary"
                  roundedType="rounded"
                  size="medium"
                  icon={
                    <Icons.ChevronRight
                      className={cn("hover:opacity-70", {
                        "rotate-90 transform": isOpen,
                      })}
                    />
                  }
                />
              </CollapsibleTrigger>
            </TableCell>
          </TableRow>

          <CollapsibleContent asChild>
            <TableRow
              className={cn("relative", {
                "border-b border-b-border-light": isOpen,
              })}
            >
              <TableCell colSpan={8}>
                <div
                  className={cn("absolute left-0 top-0 h-full w-2", {
                    "bg-primary-400": isOpen,
                  })}
                />
                <StatsSingleAgentRowDetails channels={channelsFiltered} agent={agent} />
              </TableCell>
            </TableRow>
          </CollapsibleContent>
        </>
      </Collapsible>

      <StatsDownvotedMessagesDialog
        isOpen={isDownvotedMessagesDialogOpen}
        setIsOpen={setIsDownvotedMessagesDialogOpen}
        agentId={agent._id}
        currentRange={currentRange}
        startDate={startDate}
        endDate={endDate}
      />
    </>
  );
};

export default StatsAgentsOverviewSingleAgentRow;
