import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icons } from "@/components/ui/icons";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { CustomerDetails } from "@/pages/Workspace/components/CustomerSpace/CustomerDetails";
import { LeaveWorkspaceMemberDialog } from "@/components/dialogs/TeamDialogs/LeaveWorkspaceMemberDialog";
import { IconButton } from "@/components/ui/icon-button";

export const CustomerDropdownMenu = () => {
  const { data: workspaceDetails } = useGetWorkspaceDetails();

  const isWorkspaceAdmin = workspaceDetails?.isAdmin;

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger onClick={e => e.stopPropagation()} asChild>
        <IconButton
          variant="tertiary"
          roundedType="rounded"
          icon={<Icons.ChevronRight className="h-6 w-6 rotate-90 text-black" />}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="flex flex-col gap-3 px-4 py-2.5">
        <CustomerDetails />
        {(!isWorkspaceAdmin || (isWorkspaceAdmin && workspaceDetails.customer.administrators.length !== 1)) && (
          <>
            <div className="border-b-[1.5px]" />
            <DropdownMenuItem
              className="flex items-center gap-2 text-xs"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <LeaveWorkspaceMemberDialog />
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
