import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icons } from "@/components/ui/icons";
import type { Origin } from "@/types/origin";
import { Origins } from "@/types/origin";

export const AgentChatHistoryDialogChannelPicker = ({
  activeChannel,
  onActiveChannelChange,
}: {
  activeChannel: Origin | null;
  onActiveChannelChange: (channel: Origin | null) => void;
}) => {
  const channels: {
    icon: React.ReactNode;
    channel: Origin;
    label: string;
  }[] = [
    {
      icon: <Icons.Globe className="size-[20px]" />,
      channel: Origins.EMBEDDED,
      label: "Embedded",
    },
    {
      icon: <Icons.Agent className="size-[20px]" />,
      channel: Origins.WORKSPACE,
      label: "Workspace",
    },
    {
      icon: <Icons.Galaxy className="size-[20px] text-primary-400" />,
      channel: Origins.AGENT_SPACE,
      label: "Agent space",
    },
    {
      icon: <Icons.DiscordCloud className="size-[20px]" />,
      channel: Origins.DISCORD,
      label: "Discord",
    },
    {
      icon: <Icons.SlackColor className="size-[20px]" />,
      channel: Origins.SLACK,
      label: "Slack",
    },
    {
      icon: <Icons.WhatsUp className="size-[20px] text-[#25D366]" />,
      channel: Origins.WHATSAPP,
      label: "Whatsapp",
    },
  ];

  return (
    <DropdownMenu>
      <div className="">
        <DropdownMenuTrigger className="flex items-center gap-1 [&[data-state=open]>svg]:rotate-90">
          {channels.find(channel => channel.channel === activeChannel)?.label ?? "All channels"} conversations
          <Icons.ChevronLeft className="ml-2 size-[20px] -rotate-90" />
        </DropdownMenuTrigger>
      </div>
      <DropdownMenuContent align="start">
        <DropdownMenuItem className="" onClick={() => onActiveChannelChange(null)}>
          All channels
        </DropdownMenuItem>
        {channels.map(channel => {
          return (
            <DropdownMenuItem key={channel.channel} className="" onClick={() => onActiveChannelChange(channel.channel)}>
              <div className="flex items-center gap-2">
                {channel.icon}
                <span>{channel.label}</span>
              </div>
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
