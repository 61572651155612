import { useState, type PropsWithChildren, useEffect } from "react";
import { SubscriptionTierContext } from "./SubscriptionTierDialogContext";
import { SubscriptionTierDialog } from "@/components/dialogs/SubscriptionTierDialog/SubscriptionTierDialog";
import type { BillingPlan } from "@/types/subscriptions";
import { SubscriptionPeriod } from "@/types/subscriptions";
import type { F1 } from "@/types/types";
import { useGetBillingPlans } from "@/data/queries/subscriptions/useGetBillingPlans";

export type SubscriptionTierDialogContextProps = {
  isOpen: boolean;
  openDialog: (props?: OpenDialogProps) => void;
  closeDialog: () => void;
  activeStep: SubscriptionDialogSteps;
  setActiveStep: F1<SubscriptionDialogSteps>;
  selectedPlan: SelectedPlan | null;
  setSelectedPlan: F1<SelectedPlan>;
  selectedSeats: number;
  setSelectedSeats: F1<number>;
  defaultSeats: number;
  selectedSeatsPlan: string | null;
  isPendingPayment: boolean;
  setIsPendingPayment: F1<boolean>;
};

export type SelectedPlan = { plan: BillingPlan; period: SubscriptionPeriod };

const subscriptionDialogSteps = [
  "subscriptionTiers",
  "stripePaymentSummary",
  "stripePaymentSuccess",
  "changeSubscriptionTier",
] as const;

export type SubscriptionDialogSteps = (typeof subscriptionDialogSteps)[number];

export type OpenDialogProps = {
  step: SubscriptionDialogSteps;
  selectedPlan: SelectedPlan;
};

export const SubscriptionTierDialogProvider = ({ children }: PropsWithChildren) => {
  const { data: plans } = useGetBillingPlans();
  const [isOpen, setIsOpen] = useState(false);
  const [activeStep, setActiveStep] = useState<SubscriptionDialogSteps>("subscriptionTiers");
  const [selectedPlan, setSelectedPlan] = useState<SelectedPlan | null>(null);
  const [selectedSeatsPlan, setSelectedSeatsPlan] = useState<string | null>(null);
  const [selectedSeats, setSelectedSeats] = useState<number>(0);
  const [isPendingPayment, setIsPendingPayment] = useState(false);

  const defaultSeats =
    selectedPlan?.plan.features.find(feature => feature.name === "Team seats" && feature.enabled)?.value || 0;

  useEffect(() => {
    if (selectedPlan) {
      setSelectedSeats(defaultSeats);

      const seatsPlan = plans?.userLicences?.find(license =>
        license.name.includes(selectedPlan.period === SubscriptionPeriod.MONTHLY ? selectedPlan.period : "annual")
      )?.price.planId;

      if (!seatsPlan) {
        return;
      }
      setSelectedSeatsPlan(seatsPlan);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan]);
  const openDialog = (props?: OpenDialogProps) => {
    if (props) {
      setActiveStep(props.step);
      setSelectedPlan(props.selectedPlan);
    }
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setTimeout(() => {
      setSelectedPlan(null);
      setActiveStep("subscriptionTiers");
    }, 300);
  };
  const value = {
    isOpen,
    openDialog,
    closeDialog,
    activeStep,
    setActiveStep,
    selectedPlan,
    setSelectedPlan,
    selectedSeats,
    setSelectedSeats,
    defaultSeats,
    selectedSeatsPlan,
    isPendingPayment,
    setIsPendingPayment,
  };

  return (
    <SubscriptionTierContext.Provider value={value}>
      <SubscriptionTierDialog />
      {children}
    </SubscriptionTierContext.Provider>
  );
};
