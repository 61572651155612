import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Icons } from "@/components/ui/icons";
import { useCancelSubscriptionPlan } from "@/data/mutations/subscriptions/useCancelSubscriptionPlan";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";

type CancelSubscriptionDialogProps = {
  openDialog?: boolean;
};

export const CancelSubscriptionDialog = ({ openDialog }: CancelSubscriptionDialogProps) => {
  const [isDialogOpen, setShowDialog] = useState(!!openDialog);
  const { closeDialog } = useSubscriptionTierDialogContext();
  const { mutate: cancelSubscriptionPlan, isPending: isPlanCancelationPending } = useCancelSubscriptionPlan();
  const handlePlanCancelation = () => {
    cancelSubscriptionPlan(undefined, {
      onSuccess: () => {
        setShowDialog(false);

        if (openDialog) {
          closeDialog();
        }
      },
    });
  };
  return (
    <Dialog open={isDialogOpen} onOpenChange={setShowDialog}>
      <DialogTrigger asChild>
        <Button variant="tertiary" prefixIcon={<Icons.StopCircle />}>
          Cancel Plan
        </Button>
      </DialogTrigger>
      <DialogContent variant="small">
        <DialogHeader>
          <DialogTitle className="font-bold">We’re sad to see you go!</DialogTitle>
        </DialogHeader>
        <div className="px-8 py-2 text-xs font-medium text-neutral-500">
          If you proceed, your plan will be scheduled to <span className="text-error">cancel</span> at the end of your
          current subscription period.
          <div className="mt-5 grid grid-cols-[16px_1fr] items-center gap-2 rounded-md border border-neutral-300 p-2 text-neutral-400">
            <Icons.Info className="size-4" />
            If you change your mind, you can reverse the cancellation any time before it takes effect.
          </div>
        </div>
        <DialogFooter>
          <Button
            loading={isPlanCancelationPending}
            variant="danger"
            className="w-full"
            onClick={handlePlanCancelation}
          >
            Confirm Cancelation
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
