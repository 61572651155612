import { useGetUser } from "@/data/queries/useGetUser";
import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { Icons } from "@/components/ui/icons";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import { LoginRoutes } from "@/components/Routes/LoginRoutes";

const AGENT_X_VIDEO_URL = "https://storage.googleapis.com/agentx-cdn-01/video/AgentX_open_video.mp4";

export const RegisterRoutes = () => {
  const token = localStorage.getItem("token");
  const { user } = useGetUser({ enabled: !!token });
  const isScreenXl = useTailwindMediaQuery("xl");

  if (user) {
    return <Navigate to={ROUTES.workspace} replace />;
  }

  if (!isScreenXl) {
    return <LoginRoutes />;
  }

  return (
    <div className="flex size-full h-dvh w-dvw max-w-none flex-1 overflow-hidden">
      <div className="relative h-full w-[72%]">
        <video src={AGENT_X_VIDEO_URL} autoPlay muted loop className="h-full w-full object-cover" />
      </div>
      <div className="flex h-full w-[28%] flex-col items-center gap-44 bg-gradient-to-b from-[#F6F3FF] to-[#FDF8F4] px-24 py-32">
        <Icons.AgentLogo className="max-h-[32px] w-[220px]" />
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
