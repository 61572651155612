import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import type { BillingPlanPricing } from "@/types/subscriptions";
import { SubscriptionPeriod } from "@/types/subscriptions";
import { StripePaymentWrapper } from "@/components/dialogs/SubscriptionTierDialog/components/StripePaymentSummary/components/StripePaymentWrapper";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import type { SelectedPlan } from "@/contexts/SubscriptionTierDialogContext/SubscriptionTierDialogProvider";

type StripePlanDetailsProps = {
  period: SubscriptionPeriod;
  selectedPlan: SelectedPlan;
  planPricingInfo: BillingPlanPricing;
};

export const StripePlanDetails = ({ period, planPricingInfo, selectedPlan }: StripePlanDetailsProps) => {
  const { setSelectedPlan } = useSubscriptionTierDialogContext();

  const calculateSavings = () =>
    (100 - (selectedPlan.plan.price.annually.amount / (selectedPlan.plan.price.monthly.amount * 12)) * 100).toFixed();

  return (
    <StripePaymentWrapper key={period}>
      <div className="flex items-center gap-2">
        <RadioGroup>
          <RadioGroupItem
            onClick={() => setSelectedPlan({ plan: selectedPlan?.plan, period })}
            value={selectedPlan.period}
            checked={period === selectedPlan.period}
          />
        </RadioGroup>
        <div className="text-sm font-[500] capitalize">{period}</div>
        {period === SubscriptionPeriod.ANNUALLY && (
          <div className="rounded-md bg-primary-100 p-1 text-sm text-primary-400">Save {calculateSavings()}%</div>
        )}
      </div>

      <div className="flex flex-col items-end gap-1 text-sm">
        <div>
          <span className="text-primary-400">
            ${period === SubscriptionPeriod.ANNUALLY ? (planPricingInfo.amount / 12).toFixed() : planPricingInfo.amount}
          </span>
          <span>/month</span>
        </div>
        <div className="text-neutral-500">
          {period === SubscriptionPeriod.ANNUALLY
            ? `$${planPricingInfo.amount} billed yearly`
            : `$${planPricingInfo.amount} billed monthly`}
        </div>
      </div>
    </StripePaymentWrapper>
  );
};
