import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import { useState } from "react";

const AnimatedBookmarksHistoryWrapper = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = () => setIsOpen(prev => !prev);

  return (
    <motion.div
      initial={{ width: isOpen ? "fit-content" : 0 }}
      animate={{ width: isOpen ? "fit-content" : 0 }}
      transition={{ duration: 0.3 }}
      className={cn("relative")}
    >
      <button
        className="group absolute -left-0 top-1/2 z-50 -translate-x-full -translate-y-1/2 py-8 pl-2"
        onClick={toggleOpen}
      >
        {isOpen ? (
          <>
            <Icons.HistoryPanelCloseDefault className="text-primary-400 group-hover:hidden" />
            <Icons.HistoryPanelCloseHover className="hidden text-primary-500 group-hover:block" />
          </>
        ) : (
          <Icons.HistoryPanelOpen className="text-primary-400 group-hover:text-primary-500" />
        )}

        <span className="absolute -left-0 top-1/2 hidden -translate-x-full -translate-y-1/2 whitespace-nowrap rounded-[4px] bg-primary-100 px-3 py-2 text-xs font-medium leading-5 text-neutral-600 group-hover:block">
          {isOpen ? "Close" : "Show chat history"}
          <Icons.ChevronRightFilled className="absolute -right-2 top-1/2 -translate-y-1/2 text-primary-100" />
        </span>
      </button>
      {children}
    </motion.div>
  );
};

export default AnimatedBookmarksHistoryWrapper;
