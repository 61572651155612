import type { TypeFromConstObject } from "./types";

export enum ExternalAgentPlatform {
  DISCORD = "discord",
  SLACK = "slack",
  TELEGRAM = "telegram",
  TWILIO = "twilio",
  WHATSAPP = "whatsapp",
  INSTAGRAM = "instagram",
}

export const Origins = {
  WEB: "web",
  API: "api",
  DISCORD: ExternalAgentPlatform.DISCORD,
  SLACK: ExternalAgentPlatform.SLACK,
  TELEGRAM: ExternalAgentPlatform.TELEGRAM,
  TWILIO: ExternalAgentPlatform.TWILIO,
  WHATSAPP: ExternalAgentPlatform.WHATSAPP,
  INSTAGRAM: ExternalAgentPlatform.INSTAGRAM,
  ECOMMERCE: "ecommerce",
  EMBEDDED: "embedded",
  WORKSPACE: "workspace",
  AGENT_SPACE: "agent_space",
  OTHER: "other",
} as const;
export type Origin = TypeFromConstObject<typeof Origins>;

//TODO: unify with origins after backend update
export const StatOrigins = {
  WEB: 0,
  API: 1,
  EMBEDDED: 2,
  TWILIO: 3,
  AGENT_SPACE: 4,
  WORKSPACE: 5,
  DISCORD: 6,
  SLACK: 7,
  TELEGRAM: 8,
  WHATSAPP: 9,
  INSTAGRAM: 10,
  ECOMMERCE: 11,
  OTHER: 99,
} as const;

export type StatOrigin = TypeFromConstObject<typeof StatOrigins>;

export const mapOriginToStatOrigin = (origin: Origin): StatOrigin => {
  const statOrigin = (StatOrigins as Record<string, StatOrigin>)[origin];
  return statOrigin ?? StatOrigins.WEB; // Domyślna wartość
};

export const mapStatOriginToOrigin = (statOrigin: StatOrigin): Origin => {
  const origin = (Object.keys(StatOrigins) as (keyof typeof Origins)[]).find(key => StatOrigins[key] === statOrigin);
  return origin ? Origins[origin] : Origins.WEB; // Domyślna wartość
};
