import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogFooter, DialogClose } from "@/components/ui/dialog";
import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { useCreateSlackTemporaryId } from "@/data/mutations/external-agents/useCreateSlackTemporaryId";
import { useCopyNotify } from "@/hooks/useCopyNotify";
import type { SlackAgent } from "@/types/agent";
import { useEffect } from "react";

const slackOAuthUrl = import.meta.env.VITE_SLACK_OAUTH_URL as string;

type SlackDialogProps = {
  isDialogOpen: boolean;
  setShowDialog: (value: boolean) => void;
  agentData: SlackAgent | undefined;
};

export const SlackDialog = ({ agentData, isDialogOpen, setShowDialog }: SlackDialogProps) => {
  const { getValues: getAgentFormValues } = useAgentFormContext();
  const agentId = getAgentFormValues("_id");
  const { mutate: createSlackTemporaryId, isPending: isTemporaryIdCreating } = useCreateSlackTemporaryId();
  const [, copy] = useCopyNotify();

  useEffect(() => {
    if (isDialogOpen && !agentData) {
      createSlackTemporaryId({ agentId }, { onError: () => setShowDialog(false) });
    }
  }, [isDialogOpen, agentData, createSlackTemporaryId, setShowDialog, agentId]);

  return (
    <Dialog open={isDialogOpen} onOpenChange={setShowDialog}>
      <DialogContent variant="custom" className="min-h-min w-full max-w-[650px] pb-[68px]">
        <DialogHeader>Slack bot settings</DialogHeader>
        <div className="px-5 pb-5 sm:px-8">
          {!agentData ? (
            isTemporaryIdCreating && <LoadingSpinner className="flex size-full items-center justify-center" />
          ) : (
            <>
              <div className="mb-0.5 text-sm font-bold">Connect to Slack and chat with this bot in Slack App.</div>
              <div className="mb-5 text-sm font-medium text-primary-400">
                <a
                  href="https://www.agentx.so/post/How-To-Create-AI-Agent-Chatbots-For-Slack"
                  target="_blank"
                  className="text-sm font-medium text-primary-400"
                  rel="noreferrer"
                >
                  How to get register a Slack Bot?
                </a>
              </div>
              <div className="flex flex-col gap-1.5">
                <div className="flex gap-1">
                  <Label htmlFor="slackTemporaryId">Integration Code</Label>
                  <HybridTooltipPopover>
                    <p>Copy the code and click “Add to Slack”</p>
                  </HybridTooltipPopover>
                </div>
                <Input
                  id="slackTemporaryId"
                  value={agentData.platformAttributes.temporaryId}
                  disabled
                  inputClassName="bg-primary-50 disabled:cursor-default"
                >
                  <IconButton
                    autoFocus
                    variant="tertiary"
                    size="tiny"
                    className="absolute right-3 top-1/2 -translate-y-1/2"
                    onClick={() => copy(agentData.platformAttributes.temporaryId)}
                    icon={<Icons.Copy />}
                  />
                </Input>
              </div>
            </>
          )}
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          {!!agentData && (
            <Button variant="primary">
              <a href={slackOAuthUrl} target="_blank" rel="noreferrer">
                Add to Slack
              </a>
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
