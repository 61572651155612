import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { getKnowledgeFromFile } from "@/utils/getKnowledgeFromFile";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { useState } from "react";
import { toast } from "react-toastify";

type Props = {
  onUploadSuccess: (data: { description: string; content: string }[]) => void;
  className?: string;
  inputId: string;
};

export const KnowledgeImportButton = ({ onUploadSuccess, className, inputId }: Props) => {
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false);

  const openFilePicker = () => {
    const input = document.getElementById(inputId);

    if (input) {
      input.dispatchEvent(
        new MouseEvent("click", {
          bubbles: false,
          cancelable: true,
          composed: true,
        })
      );
    }
  };

  const uploadFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files) {
      return;
    }

    if (files.length > 1) {
      toast.error("You can only upload one file at a time.");
    }

    setIsLoadingUploadFile(true);

    const file = files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await getKnowledgeFromFile(file);
      const data = res.map(({ column1: description, column2: content }) => ({ description, content }));
      onUploadSuccess(data);
    } catch (error) {
      const errorMessage = `${(error as Error).message}`;
      toast.error(errorMessage);
    } finally {
      setIsLoadingUploadFile(false);
    }
  };

  return (
    <>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="tertiary"
            size="tiny"
            roundedType="rounded"
            onClick={e => {
              e.preventDefault();
              openFilePicker();
            }}
            loading={isLoadingUploadFile}
            disabled={isLoadingUploadFile}
            prefixIcon={<Icons.Upload className="h-4 w-4" />}
            className={cn("ml-auto mr-4", className)}
          >
            Import
          </Button>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent side="top">
            <p>
              You can import knowledge data from a file (CSV, XLS, or XLSX). The values in the first two columns will be
              used to populate the fields.
            </p>
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
      <input type="file" accept=".xls,.xlsx,.csv" hidden onChange={uploadFiles} id={inputId} className="hidden" />
    </>
  );
};
