import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";

type Props = {
  selectedConversationsIds: string[];
  onSelectConversations: (ids: string[]) => void;
  onDownload: ({ conversationIds }: { conversationIds: string[] }) => void;
  isLoadingDownload: boolean;
};

export const AgentChatHistoryDialogFooter = ({
  selectedConversationsIds,
  onSelectConversations,
  onDownload,
  isLoadingDownload,
}: Props) => {
  if (!selectedConversationsIds) {
    return null;
  }

  return (
    <div className="flex items-center gap-4">
      {!!selectedConversationsIds.length && (
        <Button variant="tertiary" onClick={() => onSelectConversations([])}>
          Deselect all
        </Button>
      )}
      <Button
        onClick={() => onDownload({ conversationIds: selectedConversationsIds })}
        loading={isLoadingDownload}
        prefixIcon={<Icons.Download />}
        disabled={!selectedConversationsIds?.length}
      >
        Download
      </Button>
    </div>
  );
};
