import { LoginForm } from "@/components/LoginForm/LoginForm";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import { Icons } from "@/components/ui/icons";
import { useLoginUser } from "@/data/mutations/useLoginUser";
import { userKeys } from "@/data/queries/useGetUser";
import type { F1 } from "@/types/types";
import { useQueryClient } from "@tanstack/react-query";

type LoginDialogProps = {
  isDialogOpen: boolean;
  setShowDialog: F1<boolean>;
};

export const LoginDialog = ({ isDialogOpen, setShowDialog }: LoginDialogProps) => {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useLoginUser();

  const handleSubmitForm = ({
    email,
    password,
    recaptchaToken,
  }: {
    email: string;
    password: string;
    recaptchaToken?: string | undefined;
  }) => {
    mutate(
      { email, password, recaptchaToken },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: userKeys.all,
          });
          setShowDialog(false);
        },
      }
    );
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setShowDialog}>
      <DialogContent className="h-dvh w-full overflow-y-scroll md:h-fit md:w-fit md:min-w-[560px]" variant="custom">
        <DialogHeader className="border-none"></DialogHeader>
        <div className="flex h-full w-full flex-1 flex-col items-center gap-2.5 px-16 py-4 xl:max-w-[560px]">
          <Icons.AgentLogo className="mx-auto mb-6 max-h-8 w-[100px] sm:w-44 xl:w-[200px]" />

          <h2 className="mb-4 text-center text-xl font-bold leading-9 tracking-tight lg:text-[28px]">
            One-stop AI Agent build platform
          </h2>

          <h5 className="mb-6 text-center text-sm font-medium text-neutral-400">
            Login or create account for free conversations of all agents at AgentX
          </h5>

          <LoginForm
            isPendingSubmit={isPending}
            submitError={error}
            onSubmitForm={handleSubmitForm}
            setShowDialog={setShowDialog}
          />
        </div>
        <DialogFooter></DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
